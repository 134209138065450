/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StringGeoResponse
 */
export interface StringGeoResponse {
    /**
     * 
     * @type {string}
     * @memberof StringGeoResponse
     */
    response?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringGeoResponse
     */
    error?: string | null;
}

/**
 * Check if a given object implements the StringGeoResponse interface.
 */
export function instanceOfStringGeoResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StringGeoResponseFromJSON(json: any): StringGeoResponse {
    return StringGeoResponseFromJSONTyped(json, false);
}

export function StringGeoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringGeoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'response': !exists(json, 'response') ? undefined : json['response'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function StringGeoResponseToJSON(value?: StringGeoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response': value.response,
        'error': value.error,
    };
}

