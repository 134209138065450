/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Brokerage,
  Driver,
  ListOfLoads,
  Owner,
  State,
  UserDto,
  Vehicle,
} from '../models';
import {
    BrokerageFromJSON,
    BrokerageToJSON,
    DriverFromJSON,
    DriverToJSON,
    ListOfLoadsFromJSON,
    ListOfLoadsToJSON,
    OwnerFromJSON,
    OwnerToJSON,
    StateFromJSON,
    StateToJSON,
    UserDtoFromJSON,
    UserDtoToJSON,
    VehicleFromJSON,
    VehicleToJSON,
} from '../models';

export interface ApiListOfLoadsAllBrokeragesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsAllDriversGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsAllGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsAllOwnersGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsAllStatesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsAllUsersGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsAllVehiclesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsGetRequest {
    number?: string;
    global?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsIdDeleteRequest {
    id: number;
}

export interface ApiListOfLoadsIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsIdPutRequest {
    id: number;
    listOfLoads?: ListOfLoads;
}

export interface ApiListOfLoadsOwnerBillExportSelectedWeekPostRequest {
    selectedWeek: string;
    requestBody?: Array<number>;
}

export interface ApiListOfLoadsOwnerBillOwnersGetRequest {
    selectedWeek?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiListOfLoadsOwnerBillSendSelectedWeekPostRequest {
    selectedWeek: string;
    requestBody?: Array<number>;
}

export interface ApiListOfLoadsPayedPutRequest {
    id?: number;
    payed?: boolean;
}

export interface ApiListOfLoadsPostRequest {
    listOfLoads?: ListOfLoads;
}

export interface ApiListOfLoadsSelectedFactoringPutRequest {
    factoring?: boolean;
    requestBody?: Array<number>;
}

export interface ApiListOfLoadsSelectedPayedPutRequest {
    payed?: boolean;
    requestBody?: Array<number>;
}

export interface ApiListOfLoadsWeekExportGetRequest {
    selectedWeek?: string;
    orgName?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileListOfLoadsForUserUserIdGetRequest {
    userId: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileListOfLoadsForVehicleVehicleIdGetRequest {
    vehicleId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileListOfLoadsGetRequest {
    number?: string;
    global?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileListOfLoadsIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileListOfLoadsProfitForDriverDriverIdGetRequest {
    driverId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileListOfLoadsProfitForOwnerOwnerIdGetRequest {
    ownerId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class ListOfLoadsApi extends runtime.BaseAPI {

    /**
     */
    async apiListOfLoadsAllBrokeragesGetRaw(requestParameters: ApiListOfLoadsAllBrokeragesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Brokerage>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/all-brokerages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrokerageFromJSON));
    }

    /**
     */
    async apiListOfLoadsAllBrokeragesGet(requestParameters: ApiListOfLoadsAllBrokeragesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Brokerage>> {
        const response = await this.apiListOfLoadsAllBrokeragesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsAllDriversGetRaw(requestParameters: ApiListOfLoadsAllDriversGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Driver>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/all-drivers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverFromJSON));
    }

    /**
     */
    async apiListOfLoadsAllDriversGet(requestParameters: ApiListOfLoadsAllDriversGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Driver>> {
        const response = await this.apiListOfLoadsAllDriversGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsAllGetRaw(requestParameters: ApiListOfLoadsAllGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListOfLoads>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListOfLoadsFromJSON));
    }

    /**
     */
    async apiListOfLoadsAllGet(requestParameters: ApiListOfLoadsAllGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListOfLoads>> {
        const response = await this.apiListOfLoadsAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsAllOwnersGetRaw(requestParameters: ApiListOfLoadsAllOwnersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Owner>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/all-owners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OwnerFromJSON));
    }

    /**
     */
    async apiListOfLoadsAllOwnersGet(requestParameters: ApiListOfLoadsAllOwnersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Owner>> {
        const response = await this.apiListOfLoadsAllOwnersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsAllStatesGetRaw(requestParameters: ApiListOfLoadsAllStatesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<State>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/all-states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StateFromJSON));
    }

    /**
     */
    async apiListOfLoadsAllStatesGet(requestParameters: ApiListOfLoadsAllStatesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<State>> {
        const response = await this.apiListOfLoadsAllStatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsAllUsersGetRaw(requestParameters: ApiListOfLoadsAllUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/all-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiListOfLoadsAllUsersGet(requestParameters: ApiListOfLoadsAllUsersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDto>> {
        const response = await this.apiListOfLoadsAllUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsAllVehiclesGetRaw(requestParameters: ApiListOfLoadsAllVehiclesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Vehicle>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/all-vehicles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleFromJSON));
    }

    /**
     */
    async apiListOfLoadsAllVehiclesGet(requestParameters: ApiListOfLoadsAllVehiclesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Vehicle>> {
        const response = await this.apiListOfLoadsAllVehiclesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsGetRaw(requestParameters: ApiListOfLoadsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListOfLoads>>> {
        const queryParameters: any = {};

        if (requestParameters.number !== undefined) {
            queryParameters['number'] = requestParameters.number;
        }

        if (requestParameters.global !== undefined) {
            queryParameters['global'] = requestParameters.global;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListOfLoadsFromJSON));
    }

    /**
     */
    async apiListOfLoadsGet(requestParameters: ApiListOfLoadsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListOfLoads>> {
        const response = await this.apiListOfLoadsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsIdDeleteRaw(requestParameters: ApiListOfLoadsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiListOfLoadsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiListOfLoadsIdDelete(requestParameters: ApiListOfLoadsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiListOfLoadsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiListOfLoadsIdGetRaw(requestParameters: ApiListOfLoadsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOfLoads>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiListOfLoadsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOfLoadsFromJSON(jsonValue));
    }

    /**
     */
    async apiListOfLoadsIdGet(requestParameters: ApiListOfLoadsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOfLoads> {
        const response = await this.apiListOfLoadsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsIdPutRaw(requestParameters: ApiListOfLoadsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOfLoads>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiListOfLoadsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ListOfLoadsToJSON(requestParameters.listOfLoads),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOfLoadsFromJSON(jsonValue));
    }

    /**
     */
    async apiListOfLoadsIdPut(requestParameters: ApiListOfLoadsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOfLoads> {
        const response = await this.apiListOfLoadsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsOwnerBillExportSelectedWeekPostRaw(requestParameters: ApiListOfLoadsOwnerBillExportSelectedWeekPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.selectedWeek === null || requestParameters.selectedWeek === undefined) {
            throw new runtime.RequiredError('selectedWeek','Required parameter requestParameters.selectedWeek was null or undefined when calling apiListOfLoadsOwnerBillExportSelectedWeekPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/owner-bill/export/{selectedWeek}`.replace(`{${"selectedWeek"}}`, encodeURIComponent(String(requestParameters.selectedWeek))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiListOfLoadsOwnerBillExportSelectedWeekPost(requestParameters: ApiListOfLoadsOwnerBillExportSelectedWeekPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiListOfLoadsOwnerBillExportSelectedWeekPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsOwnerBillOwnersGetRaw(requestParameters: ApiListOfLoadsOwnerBillOwnersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Owner>>> {
        const queryParameters: any = {};

        if (requestParameters.selectedWeek !== undefined) {
            queryParameters['selectedWeek'] = requestParameters.selectedWeek;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/owner-bill/owners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OwnerFromJSON));
    }

    /**
     */
    async apiListOfLoadsOwnerBillOwnersGet(requestParameters: ApiListOfLoadsOwnerBillOwnersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Owner>> {
        const response = await this.apiListOfLoadsOwnerBillOwnersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsOwnerBillSendSelectedWeekPostRaw(requestParameters: ApiListOfLoadsOwnerBillSendSelectedWeekPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.selectedWeek === null || requestParameters.selectedWeek === undefined) {
            throw new runtime.RequiredError('selectedWeek','Required parameter requestParameters.selectedWeek was null or undefined when calling apiListOfLoadsOwnerBillSendSelectedWeekPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/owner-bill/send/{selectedWeek}`.replace(`{${"selectedWeek"}}`, encodeURIComponent(String(requestParameters.selectedWeek))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiListOfLoadsOwnerBillSendSelectedWeekPost(requestParameters: ApiListOfLoadsOwnerBillSendSelectedWeekPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiListOfLoadsOwnerBillSendSelectedWeekPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsPayedPutRaw(requestParameters: ApiListOfLoadsPayedPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.payed !== undefined) {
            queryParameters['payed'] = requestParameters.payed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/payed`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiListOfLoadsPayedPut(requestParameters: ApiListOfLoadsPayedPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiListOfLoadsPayedPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiListOfLoadsPostRaw(requestParameters: ApiListOfLoadsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOfLoads>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ListOfLoadsToJSON(requestParameters.listOfLoads),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOfLoadsFromJSON(jsonValue));
    }

    /**
     */
    async apiListOfLoadsPost(requestParameters: ApiListOfLoadsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOfLoads> {
        const response = await this.apiListOfLoadsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiListOfLoadsSelectedFactoringPutRaw(requestParameters: ApiListOfLoadsSelectedFactoringPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.factoring !== undefined) {
            queryParameters['factoring'] = requestParameters.factoring;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/selected/factoring`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiListOfLoadsSelectedFactoringPut(requestParameters: ApiListOfLoadsSelectedFactoringPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiListOfLoadsSelectedFactoringPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiListOfLoadsSelectedPayedPutRaw(requestParameters: ApiListOfLoadsSelectedPayedPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.payed !== undefined) {
            queryParameters['payed'] = requestParameters.payed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/selected/payed`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiListOfLoadsSelectedPayedPut(requestParameters: ApiListOfLoadsSelectedPayedPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiListOfLoadsSelectedPayedPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiListOfLoadsWeekExportGetRaw(requestParameters: ApiListOfLoadsWeekExportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.selectedWeek !== undefined) {
            queryParameters['selectedWeek'] = requestParameters.selectedWeek;
        }

        if (requestParameters.orgName !== undefined) {
            queryParameters['orgName'] = requestParameters.orgName;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/list-of-loads/week-export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiListOfLoadsWeekExportGet(requestParameters: ApiListOfLoadsWeekExportGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiListOfLoadsWeekExportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileListOfLoadsForUserUserIdGetRaw(requestParameters: ApiMobileListOfLoadsForUserUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListOfLoads>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiMobileListOfLoadsForUserUserIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/list-of-loads/forUser/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListOfLoadsFromJSON));
    }

    /**
     */
    async apiMobileListOfLoadsForUserUserIdGet(requestParameters: ApiMobileListOfLoadsForUserUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListOfLoads>> {
        const response = await this.apiMobileListOfLoadsForUserUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileListOfLoadsForVehicleVehicleIdGetRaw(requestParameters: ApiMobileListOfLoadsForVehicleVehicleIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListOfLoads>>> {
        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling apiMobileListOfLoadsForVehicleVehicleIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/list-of-loads/forVehicle/{vehicleId}`.replace(`{${"vehicleId"}}`, encodeURIComponent(String(requestParameters.vehicleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListOfLoadsFromJSON));
    }

    /**
     */
    async apiMobileListOfLoadsForVehicleVehicleIdGet(requestParameters: ApiMobileListOfLoadsForVehicleVehicleIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListOfLoads>> {
        const response = await this.apiMobileListOfLoadsForVehicleVehicleIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileListOfLoadsGetRaw(requestParameters: ApiMobileListOfLoadsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ListOfLoads>>> {
        const queryParameters: any = {};

        if (requestParameters.number !== undefined) {
            queryParameters['number'] = requestParameters.number;
        }

        if (requestParameters.global !== undefined) {
            queryParameters['global'] = requestParameters.global;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/list-of-loads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListOfLoadsFromJSON));
    }

    /**
     */
    async apiMobileListOfLoadsGet(requestParameters: ApiMobileListOfLoadsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ListOfLoads>> {
        const response = await this.apiMobileListOfLoadsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileListOfLoadsIdGetRaw(requestParameters: ApiMobileListOfLoadsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOfLoads>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMobileListOfLoadsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/list-of-loads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOfLoadsFromJSON(jsonValue));
    }

    /**
     */
    async apiMobileListOfLoadsIdGet(requestParameters: ApiMobileListOfLoadsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOfLoads> {
        const response = await this.apiMobileListOfLoadsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileListOfLoadsProfitForDriverDriverIdGetRaw(requestParameters: ApiMobileListOfLoadsProfitForDriverDriverIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.driverId === null || requestParameters.driverId === undefined) {
            throw new runtime.RequiredError('driverId','Required parameter requestParameters.driverId was null or undefined when calling apiMobileListOfLoadsProfitForDriverDriverIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/list-of-loads/profitForDriver/{driverId}`.replace(`{${"driverId"}}`, encodeURIComponent(String(requestParameters.driverId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiMobileListOfLoadsProfitForDriverDriverIdGet(requestParameters: ApiMobileListOfLoadsProfitForDriverDriverIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiMobileListOfLoadsProfitForDriverDriverIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileListOfLoadsProfitForOwnerOwnerIdGetRaw(requestParameters: ApiMobileListOfLoadsProfitForOwnerOwnerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
            throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling apiMobileListOfLoadsProfitForOwnerOwnerIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/list-of-loads/profitForOwner/{ownerId}`.replace(`{${"ownerId"}}`, encodeURIComponent(String(requestParameters.ownerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiMobileListOfLoadsProfitForOwnerOwnerIdGet(requestParameters: ApiMobileListOfLoadsProfitForOwnerOwnerIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apiMobileListOfLoadsProfitForOwnerOwnerIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
