import React from 'react';
import LogisticsDataGrid from '../../components/data-grid/LogisticsDataGrid';
import { getDriversPage as getDriversEntities } from './DriversSlice';
import useDriversColumns from './DriversColumnDef';
import VisibilityToggler from '../../components/VisibilityToggler';
import AddButton from '../../components/data-grid/buttons/AddButton';
import serverFilterComponent, { serverFilterFunctionName } from '../../components/filters/server/ServerFilter';
import ServerActiveCheckbox from '../../components/filters/server/ServerActiveCheckbox';
import { ApiDriversGetRequest, Driver } from '../../generated-api';
import { RootState } from '../../store/store';
import useRights from '../../hooks/RightsHook';
import { useModal } from '../../hooks';
import DriverEdit from './DriverEdit';
import StyledInputText from '../../components/form/StyledInputText';

const selectDriver = (state: RootState) => state.driver;

function DriversList() {
  const driverModal = useModal<Driver>({});
  const driverRights = useRights(security => security.driver);

  const driverEdit = driverModal.visible
    ? <DriverEdit 
      data={driverModal.data}
      loading={false}
      visible={driverModal.visible}
      hide={driverModal.hide}
      openedFromRequest={false}
    />
    : <></>;

  return (
    <>
      <LogisticsDataGrid
        selectState={selectDriver}
        getEntitiesPage={getDriversEntities}
        columns={useDriversColumns()}
        leftHeaderElements={[
          <VisibilityToggler visible={driverRights.create}>
            <AddButton onClick={() => driverModal.show({})} />
          </VisibilityToggler>
        ]}
        id='driversList'
        onRowClick={e => driverModal.show(e.data)}
        externalFilters={[
          {
            ...serverFilterComponent(props => <ServerActiveCheckbox {...props} key='active_filter' />),
            serverFilterRequest: (filterMetaData, serverFilters) => {
              return { ...serverFilters, active: filterMetaData.value } as ApiDriversGetRequest;
            },
            field: 'active'
          },
          {
            field: 'phone',
            ...serverFilterComponent(props => <StyledInputText
              value={props.value}
              onChange={e => props.filterApplyCallback(e.target.value)}
              placeholder='Type phone number...'
              className='w-15rem'
            />),
            serverFilterRequest: (filterMetaData, serverFilterModel: ApiDriversGetRequest) => {
              return { ...serverFilterModel, phone: filterMetaData.value } as ApiDriversGetRequest;
            }
          }
        ]}
        alwaysDefaultFilterMeta={{
          'active': { value: true, matchMode: serverFilterFunctionName as any},
        }}
        storedDefaultFilterMeta={{
          'phone': { value: '', matchMode: serverFilterFunctionName as any},
        }}
        others={{
          selectionMode: 'single',
        }}
      />
      {driverEdit}
    </>
  );
}

export default DriversList;