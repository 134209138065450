import { apiFactory, ModalParameters } from '../../shared';
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Brokerage,
  BrokeragesApi,
  Driver,
  DriversApi, EDispatchStatus,
  Owner,
  OwnersApi,
  StatesApi,
  Tracking,
  TrackingsApi,
} from '../../generated-api';
import { FormikErrors, useFormik } from 'formik';
import { showErrorToast, showSuccessToast } from '../../components/LogisticsToast';
import { useAppDispatch, useAppSelector, useModal } from '../../hooks';
import { updateTracking } from './TrackingSlice';
import { logisticsConfirmDialog } from '../../components/LogisticsConfifmDialog';
import LogisticsDialog from '../../components/LogisticsDialog';
import Row from '../../components/form/Row';
import Column from '../../components/form/Column';
import Field from '../../components/form/Field';
import StyledInputText from '../../components/form/StyledInputText';
import LogisticsCalendar from '../../components/form/datetime/LogisticsCalendar';
import EditableDropdown from '../../components/form/EditableDropdown';
import { join } from 'lodash';
import emptyArrowFunction from '../../shared/util/emptyArrowFunction';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';
import TrackingOptions from '../../data-moqs/TrackingOptions';
import { IdName } from '../../data-moqs/IdName';
import { Button } from 'primereact/button';
import ErrorBox from '../../components/form/ErrorBox';
import dayjs from 'dayjs';
import CenteredSpinner from '../../components/CenteredSpinner';
import LogisticsFileUpload from '../../components/form/files/upload/LogisticsFileUpload';
import LogisticsPhoneInput from '../../components/LogisticsPhoneInput';
import LogisticsWeekCalendar from '../../components/form/datetime/LogisticsWeekCalendar';
import LsNumber, { lsNumberToString, stringToLsNumber } from '../list-of-loads/LsNumber';
import { DatePickerProps, Typography } from 'antd';
import LogisticsTimeOnly from '../../components/form/datetime/LogisticsTimeOnly';
import { getSystemSetting } from '../settings/SettingsSlice';
import useRights from '../../hooks/RightsHook';
import VisibilityToggler from '../../components/VisibilityToggler';
import useFilePreview from '../../components/form/files/preview/filePreviewWidget';
import LogisticsFilePreview from '../../components/form/files/preview/LogisticsFilePreview';
import ListOfLoadsEdit from '../list-of-loads/ListOfLoadsEdit';
import TrackingStatus from '../../data-moqs/TrackingStatus';

const { Link } = Typography;

export interface TrackingEditParameters extends ModalParameters<Tracking> {

}

interface TrackingWithLsNumber extends Tracking {
  lsNumber? : LsNumber;
}

const DispatchStatus: IdName<string>[] = [
  { id: EDispatchStatus.NotConfirm, name: 'Not confirm' },
  { id: EDispatchStatus.InTransitToPickUp, name: 'In transit to Pick up' },
  { id: EDispatchStatus.Arrived, name: 'Arrived' },
  { id: EDispatchStatus.WaitToGoodToGo, name: 'Wait to good to go' },
  { id: EDispatchStatus.GoodToGo, name: 'Good to go' },
  { id: EDispatchStatus.InTransitToDelivery, name: 'In transit to delivery' },
  { id: EDispatchStatus.Finished, name: 'Finished' },
];

function TrackingEdit(props: TrackingEditParameters) {
  const { visible } = props;
  const [tracking, setTracking] = useState<Tracking>(props.data);
  const [loading, setLoading] = useState<boolean>(props.loading);
  const [states, setStates] = useState<string[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [owners, setOwners] = useState<Owner[]>([]);
  const [brokerages, setBrokerages] = useState<Brokerage[]>([]);
  const systemSettings = useAppSelector(state => state.settings.system);
  const dispatch = useAppDispatch();
  const rights = useRights(security => security.tracking);
  const filePreviewWidget = useFilePreview();
  const lolModal = useModal<number | undefined>(undefined);
  const validation = useFormik<TrackingWithLsNumber>({
    initialValues: {
      ...tracking,
      lsNumber: tracking.number
        ? stringToLsNumber(tracking.number)
        : { abbreviation: systemSettings.abbreviation, week: dayjs().tz(), consecutiveNumber: 0 } as LsNumber
    },
    validate: data => {
      const errors: FormikErrors<Tracking> = {};
      const blankError = 'This value should not be blank.';

      const requiredFields: Tracking = {
        createTime: undefined as any,
        pickUpAtCity: undefined as any,
        pickUpAtState: undefined as any,
        deliverToCity: undefined as any,
        deliverToState: undefined as any,
        driver: undefined as any,
        mail: undefined as any,
        period: undefined as any,
        lastTime: undefined as any,
        nextTime: undefined as any,
        owner: undefined as any,
        option: undefined as any,
      } as any;

      Object.keys(requiredFields).forEach(field => {
        if (!(data as any)[field])
          (errors as any)[field] = blankError;
      });
      return errors;
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!visible) return;

    setLoading(true);
    const trackingPromise = props.data.id
      ? apiFactory(TrackingsApi).apiTrackingsIdGet({ id : props.data.id})
      : Promise.resolve(tracking);
    const settingsPromise = systemSettings.abbreviation
      ? Promise.resolve()
      : dispatch(getSystemSetting({ id: 1}));

    Promise.all([
      trackingPromise,
      apiFactory(StatesApi).apiStatesCodesGet(),
      apiFactory(DriversApi).apiDriversFullNamesAndPhoneGet(),
      apiFactory(OwnersApi).apiOwnersFullNameCompanyGet(),
      apiFactory(BrokeragesApi).apiBrokeragesNamesGet(),
      settingsPromise
    ]).then(([
      loadedTracking,
      loadedStates,
      loadedDrivers,
      loadedOwners,
      loadedBrokerages,
    ]) => {
      const newLastTime = new Date();
      const value = {...loadedTracking, lastTime: newLastTime, nextTime: setNextTime(loadedTracking.period, newLastTime)};
      setValue(
        value,
        false);
      const lsNumber = loadedTracking.number
        ? stringToLsNumber(loadedTracking.number)
        : { abbreviation: systemSettings.abbreviation, week: dayjs().tz(), consecutiveNumber: 0 } as LsNumber;
      setStates(loadedStates.map(state => state.code!));
      setDrivers(loadedDrivers);
      setOwners(loadedOwners);
      setBrokerages(loadedBrokerages);
      validation.resetForm({ values: { ...value, lsNumber: lsNumber }, touched: {}});
    }).catch(() => showErrorToast('Error on loading data'))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const setValue = useCallback((setState: SetStateAction<Tracking>, shouldValidate: boolean = true) => {
    validation.setValues(setState, shouldValidate);
    setTracking(setState);
  }, [validation]);

  const setNextTime = (period?: string | null | undefined, lastTime?: Date | null) => {
    if (!period || !lastTime) return undefined;
    const date = dayjs(lastTime).tz();
    const time = dayjs(period, 'HH:mm:ss');

    const combinedDateTime = date.add(24 + time.hour(), 'hour')
                           .add(time.minute(), 'minute')
                           .add(time.second(), 'second')
                           .add(time.millisecond(), 'millisecond');
    return combinedDateTime.toDate();
  }

  const onWeekChange: DatePickerProps['onChange'] = (newWeek) => {
    // Remove event listeners for week clicking events
    document.querySelectorAll<HTMLElement>('.ant-picker-cell-week').forEach((e) => {
      e.removeEventListener("click", handleCalendarClick);
    });

    if (!newWeek) return;

    const initialWeek = stringToLsNumber(tracking.number).week;
    const newNumber = newWeek.diff(initialWeek, 'week') || newWeek.diff(initialWeek, 'year')
      ? { ...validation.values.lsNumber, week: newWeek, consecutiveNumber: 0, abbreviation: systemSettings.abbreviation } as LsNumber
      : stringToLsNumber(tracking.number);

    setValue(tracking => { return {...tracking, number: lsNumberToString(newNumber)}})
    validation.setValues(previousValue => {
      return { ...previousValue, lsNumber: newNumber };
    });
  }

  const handleCalendarClick = (e: MouseEvent) => {
    // @ts-ignore
    e.target!.nextSibling!.click();
  };

  const hide = useCallback((tracking : Tracking | undefined) => {
    if (tracking) {
      dispatch(updateTracking(tracking));
    }

    props.hide(tracking);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnHide = useCallback(() => {
    if (!rights.update && !rights.create) {
      props.hide(tracking);
      return;
    }

    if (validation.dirty) {
      logisticsConfirmDialog({
        message: 'Save changes?',
        closable: false,
        accept() {
          save();
        },
        reject() {
          hide(undefined);
        }
      });
    } else {
      hide(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hide, tracking, validation]);

  const validate = useCallback(async () => {
    validation.handleSubmit();
    const errors = await validation.validateForm(tracking);
    if (Object.keys(errors).length) {
      throw new Error('Fix errors and try again');
    }
  }, [tracking, validation]);

  const save = useCallback(async () => {
    try {
      await validate();
      setLoading(true);

      const number = validation.touched.lsNumber || !tracking.id
        ? { ...validation.values.lsNumber, abbreviation: systemSettings.abbreviation!, consecutiveNumber: 0 } as LsNumber
        : validation.values.lsNumber!;

      const trackingForSave = {
        ...tracking,
        number: lsNumberToString(number),
      }
      const response = tracking.id
        ? await apiFactory(TrackingsApi)
          .apiTrackingsIdPut({ id: trackingForSave.id!, tracking: trackingForSave})
        : await apiFactory(TrackingsApi)
          .apiTrackingsPost({ tracking: trackingForSave });
      showSuccessToast('Tracking saved');
      if (response) {
        setTracking(response);
      }

      validation.resetForm({ values: response || trackingForSave, touched: {}});
      hide(response || trackingForSave);
    } catch (e: any) {
      showErrorToast(e.toString());
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracking, validation, validate, hide]);

  const formHeader = useMemo(() =>
    'Tracking' + (tracking?.number ? ` - ${tracking.number}` : ''),
    [tracking?.number]);

  const lolEdit = useMemo(() => {
    if (!lolModal.data) {
      return <></>;
    }

    return <ListOfLoadsEdit
      data={{id: lolModal.data}}
      loading={false}
      visible={lolModal.visible}
      hide={lolModal.hide}
    />
  }, [lolModal.visible, lolModal.data, lolModal.hide]);

  return (
    <>
      {lolEdit}
      <LogisticsDialog
        visible={props.visible}
        onHide={handleOnHide}
        className='logistics-dialog-edit-form logistics-dialog-edit-form_loadable w-8'
      >
        <header className='text-2xl w-full flex-wrap mb-5'>
          <span className='font-bold'>{formHeader}&nbsp;</span>
          {tracking.listsOfLoads?.length && (
            <Link underline className='text-lg ml-4' onClick={() => lolModal.show(tracking.listsOfLoads![0].id || 0)}>
              Loads
            </Link>
          )}
        </header>
        <main className='grid logistics-dialog-edit-form__content'>
          <Panel header='Tracking information' className='w-full'>
            <Row>
              <Column>
                <Field label='#'>
                  <StyledInputText
                    // @ts-ignore
                    value={tracking.number}
                    onChange={e => setValue(t => {
                      return { ...t, number: e.target.value || '0'};
                    })}
                    disabled={true}
                  />
                </Field>
              </Column>
              <Column>
                <Field label='Date' required>
                  <LogisticsCalendar
                    value={tracking.createTime}
                    disabled={!rights.update}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, createTime: newValue! };
                      });
                    }}
                  />
                  <ErrorBox>{validation.errors.createTime}</ErrorBox>
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='From' required>
                  <StyledInputText
                    // @ts-ignore
                    value={tracking.pickUpAtCity}
                    disabled={!rights.update}
                    onChange={e => {
                      setValue(tracking => {
                        return { ...tracking, pickUpAtCity: e.target.value };
                      })
                    }}
                  />
                  <ErrorBox>{validation.errors.pickUpAtCity}</ErrorBox>
                </Field>
              </Column>
              <Column>
                <Field label='State' required>
                  <EditableDropdown
                    options={states}
                    optionLabel=''
                    renderOption={x => x}
                    renderSelected={x => x}
                    renderEmptySelected={() => '-'}
                    value={tracking.pickUpAtState}
                    disabled={!rights.update}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, pickUpAtState: newValue };
                      })
                    }}
                    filter
                  />
                  <ErrorBox>{validation.errors.pickUpAtState}</ErrorBox>
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='To' required>
                  <StyledInputText
                    // @ts-ignore
                    value={tracking.deliverToCity}
                    disabled={!rights.update}
                    onChange={e => {
                      setValue(tracking => {
                        return { ...tracking, deliverToCity: e.target.value };
                      })
                    }}
                  />
                  <ErrorBox>{validation.errors.deliverToCity}</ErrorBox>
                </Field>
              </Column>
              <Column>
                <Field label='State' required>
                  <EditableDropdown
                    options={states}
                    optionLabel=''
                    renderOption={x => x}
                    renderSelected={x => x}
                    renderEmptySelected={() => '-'}
                    value={tracking.deliverToState}
                    disabled={!rights.update}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, deliverToState: newValue };
                      })
                    }}
                    filter
                  />
                  <ErrorBox>{validation.errors.deliverToState}</ErrorBox>
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='Driver' required>
                  <EditableDropdown
                    options={drivers}
                    optionLabel='firstName'
                    renderOption={driverFullName}
                    renderSelected={driverFullName}
                    renderEmptyOption={() => 'No driver'}
                    renderEmptySelected={() => 'No driver'}
                    value={tracking.driver}
                    disabled={!rights.update}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, driver: newValue };
                      })
                    }}
                    filter
                  />
                  <ErrorBox>{validation.errors.driver}</ErrorBox>
                </Field>
              </Column>
              <Column>
                <Field label='Phone'>
                  <LogisticsPhoneInput
                    country={'us'}
                    value={driverPhones(tracking.driver)}
                    disabled
                  />
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='Mail' required>
                  <StyledInputText
                    // @ts-ignore
                    value={tracking.mail}
                    disabled={!rights.update}
                    onChange={e => {
                      setValue(tracking => {
                        return { ...tracking, mail: e.target.value };
                      })
                    }}
                  />
                  <ErrorBox>{validation.errors.mail}</ErrorBox>
                </Field>
              </Column>
              <Column>
                <Field label='Period'>
                  <LogisticsTimeOnly
                    value={tracking.period}
                    disabled={!rights.update}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, period: newValue, nextTime: setNextTime(newValue, tracking.lastTime)};
                      })
                    }}
                  />
                  <ErrorBox>{validation.errors.period}</ErrorBox>
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='Last time' required>
                  <LogisticsCalendar
                    value={tracking.lastTime}
                    disabled={!rights.update}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, lastTime: newValue!, nextTime: setNextTime(tracking.period, newValue)};
                      })
                    }}
                    showTime
                    showSetToNowButton
                  />
                  <ErrorBox>{validation.errors.lastTime}</ErrorBox>
                </Field>
              </Column>
              <Column>
                <Field label='Next time' required>
                  <LogisticsCalendar
                    value={tracking.nextTime}
                    onChange={emptyArrowFunction}
                    showTime
                    disabled
                  />
                  <ErrorBox>{validation.errors.nextTime}</ErrorBox>
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='Comment'>
                  <StyledInputText
                    // @ts-ignore
                    value={tracking.trackerComment}
                    disabled={!rights.update}
                    onChange={e => {
                      setValue(tracking => {
                        return { ...tracking, trackerComment: e.target.value };
                      })
                    }}
                  />
                </Field>
              </Column>
              <Column>
                <Field label='Status'>
                  <EditableDropdown
                    options={TrackingStatus}
                    optionLabel='id'
                    renderOption={option => option.name}
                    renderSelected={status => status?.name}
                    renderEmptySelected={() => '-'}
                    renderEmptyOption={() => '-'}
                    value={convertEnum(tracking.status, TrackingStatus)}
                    disabled={!rights.update}
                    onChange={newStatus => setValue(v => {
                      return { ...v, status: newStatus?.id as any } as Tracking;
                    })}
                    filter
                  />
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='Week' required>
                  <LogisticsWeekCalendar
                    value={validation.values.lsNumber?.week}
                    disabled={!rights.update}
                    onChange={onWeekChange}
                    handleClick={handleCalendarClick}
                    onEditOrCreateForm
                  />
                </Field>
              </Column>
            </Row>
            <CenteredSpinner visible={loading} />
          </Panel>
          <Panel header='Dispatcher information' className='w-full mt-3'>
            <Row>
              <Column className='col-6'>
                <Field label='Life360/Link'>
                  <StyledInputText
                    // @ts-ignore
                    value={tracking.linkTracking}
                    disabled={!rights.update}
                    onChange={e => setValue(t => {
                      return { ...t, linkTracking: e.target.value };
                    })}
                  />

                </Field>
              </Column>
              <Column className='col-3'>
                <div className='field-checkbox'>
                  <label>Freight</label>
                  <Checkbox
                    checked={tracking.freight}
                    disabled={!rights.update}
                    onChange={e => {
                      setValue(tracking => {
                        return { ...tracking, freight: e.checked };
                      });
                    }}
                  />
                </div>
              </Column>
              <Column className='col-3'>
                <div className='field-checkbox'>
                  <label>MP</label>
                  <Checkbox
                    checked={tracking.mp}
                    disabled={!rights.update}
                    onChange={e => {
                      setValue(tracking => {
                        return { ...tracking, mp: e.checked };
                      });
                    }}
                  />
                </div>
              </Column>
            </Row>
            <Row>
              <Column className='w-full'>
                <Field label='Owner' required>
                  <EditableDropdown
                    options={owners}
                    optionLabel='firstName'
                    renderOption={ownerFullName}
                    renderSelected={ownerFullName}
                    renderEmptySelected={() => 'No owner'}
                    value={tracking.owner}
                    disabled={!rights.update}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, owner: newValue };
                      })
                    }}
                    filter
                  />
                  <ErrorBox>{validation.errors.owner}</ErrorBox>
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='Option' required>
                  <EditableDropdown
                    options={TrackingOptions}
                    optionLabel='id'
                    renderOption={option => option.name}
                    renderSelected={option => option?.name}
                    renderEmptySelected={() => '-'}
                    value={convertEnum(tracking.option, TrackingOptions)}
                    disabled={!rights.update}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, option: newValue.id as any};
                      });
                    }}
                  />
                  <ErrorBox>{validation.errors.option}</ErrorBox>
                </Field>
              </Column>
              <Column>
                <Field label='Comment'>
                  <StyledInputText
                    // @ts-ignore
                    value={tracking.dispatcherComment}
                    disabled={!rights.update}
                    onChange={e => {
                      setValue(tracking => {
                        return { ...tracking, dispatcherComment: e.target.value };
                      })
                    }}
                  />
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='Brokerage' required>
                  <EditableDropdown
                    options={brokerages}
                    optionLabel='name'
                    renderOption={x => x.name}
                    renderSelected={x => x?.name}
                    renderEmptySelected={() => '-'}
                    value={tracking.brokerage}
                    disabled={!rights.update}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, brokerage: newValue };
                      })
                    }}
                    filter
                  />
                  <ErrorBox>{validation.errors.brokerage}</ErrorBox>
                </Field>
              </Column>
              <Column>
                <Field label={'Status'}>
                  <EditableDropdown
                    options={DispatchStatus}
                    optionLabel='id'
                    renderOption={option => option.name}
                    renderSelected={status => status?.name}
                    renderEmptySelected={() => '-'}
                    value={convertEnum(tracking.dispStatus, DispatchStatus)}
                    disabled={!rights.update}
                    onChange={newStatus => setValue(v => {
                      return { ...v, dispStatus: newStatus?.id as any } as Tracking;
                    })}
                    filter
                  />
                </Field>
              </Column>
            </Row>
            <Row>
              <Column>
                <Field label='Rate Confirmation'>
                  <LogisticsFileUpload
                    value={tracking.rateConfirmation}
                    disabled={!rights.update}
                    filePreviewWidget={filePreviewWidget}
                    onChange={newValue => {
                      setValue(tracking => {
                        return { ...tracking, rateConfirmation: newValue}
                      });
                    }}
                  />
                </Field>
              </Column>
              <Column>
                {/*empty*/}
              </Column>
            </Row>
            <CenteredSpinner visible={loading} />
          </Panel>
        </main>
        <footer className='flex justify-content-center w-full gap-3'>
          <VisibilityToggler visible={rights.update || rights.create}>
            <Button
              label='SAVE'
              className='p-button-success'
              onClick={save}
            />
          </VisibilityToggler>
          <Button
            label='CLOSE'
            onClick={handleOnHide}
          />
        </footer>
        <LogisticsFilePreview filePreviewWidget={filePreviewWidget} />
      </LogisticsDialog>
    </>
  )
}

const driverFullName = (driver: Driver | undefined) =>
  driver ? `${driver.firstName || ''} ${driver.lastName || ''} #${driver.id}` : '';
const ownerFullName = (owner: Owner | undefined) =>
  owner ? `${owner.firstName || ''} ${owner.lastName || ''} (${owner.companyName || ''})` : '';
const driverPhones = (driver: Driver | undefined) => {
  if (!driver) return '';

  return join(driver.phones, ', ');
}

const convertEnum = (enumValue: any, enumValues: IdName<string>[]) =>
  enumValue ? enumValues.find(x => x.id === enumValue.toString()) : undefined

export default TrackingEdit;
