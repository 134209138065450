import React, { useCallback, useEffect, useMemo } from 'react';
import { DashboardApi } from '../../../generated-api';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { apiFactory } from '../../../shared';
import DashboardPanel from '../components/DashboardPanel';
import WidgetToolbar, { WidgetFilters } from '../components/WidgetToolbar';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip, TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import colorPalette from '../components/DispatcherStatisticsColorPalette';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';
import FileSaver from 'file-saver';
import { showSuccessToast } from '../../../components/LogisticsToast';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { getDashboardDispatchersDispatcherStatistics } from '../DashboardSlice';
import { getPeriodFromWidgetFilters } from './utils/widgets-utils';
import { Payload as LegendPayload} from 'recharts/types/component/DefaultLegendContent';
import { Payload as TooltipPayload} from 'recharts/types/component/DefaultTooltipContent';

dayjs.extend(advancedFormat);

const loadsSuffix = '_loads';
const opacityLevels = ["", "b3", "80"]; // 100%, 70%, 50%

type DispatcherStyle = {
  color: string,
  name: string,
}

function DispatchersStatistics() {
  const appDispatch = useAppDispatch();
  const dispatchers = useAppSelector(state => state.dashboard.dispatcherState?.dispatcherStatistics);
  const initialFilters : WidgetFilters = {
    periodType: 'Week',
    from: today().add(-11, 'week').toDate(),
    to: today().toDate(),
    dispatcherIds: dispatchers?.map(x => x.id || '') || [],
  };

  const { data, filters, loadData, setFilters } = useWidget({
    initialFilters: initialFilters,
    initialWidgetData: {},
    loadWidgetData: filters => apiFactory(DashboardApi)
      .apiDashboardDispatcherStatisticsGet(filters),
    usesDispatchers: true,
    dispatcherState: state => state.dashboard.dispatcherState?.dispatcherStatistics,
    getDispatcherSliceAction: getDashboardDispatchersDispatcherStatistics,
  });

  useEffect(() => {
    appDispatch(getDashboardDispatchersDispatcherStatistics(initialFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatcherStyles = useMemo(() => {
    let byId : { [key: string]: DispatcherStyle} = {};

    data.dispatchers?.forEach((dispatcherResponse, index) => {
      const dispatcher = dispatchers?.find(d => d.id === dispatcherResponse.dispatcherId);
      if (!dispatcher) {
        return;
      }

      const opacity = opacityLevels[Math.floor(index / colorPalette.length) % opacityLevels.length];
      const color = colorPalette[index % (colorPalette.length - 1)] + opacity;
      const name = `${dispatcher.firstName} ${dispatcher.lastName}`;
      byId[dispatcherResponse.dispatcherId!] = { color, name };
    });

    return byId;
  }, [data.dispatchers, dispatchers]);

  const legendPayload : LegendPayload[] = useMemo(() => {
    if (!data.dispatchers?.length || !dispatcherStyles) {
      return [];
    }

    return data.dispatchers.map(dispatcher => {
      const style = dispatcherStyles[dispatcher.dispatcherId!];
      return {
        color: style?.color,
        id: dispatcher.dispatcherId!,
        value: style?.name || 'undefined',
        type: 'square',
      };
    });
  }, [dispatcherStyles, data.dispatchers]);

  const chartData = useMemo(() => {
    if (!data?.periods?.length) return [];

    return data.periods.map((period, periodIndex) => {
      let dataForPeriod = { period };
      (dataForPeriod as any)[period + loadsSuffix] = data.totalLoads
        ? data.totalLoads[periodIndex]
        : 0;

      // Ordering inside column is tricky:
      // https://mabdelsattar.medium.com/recharts-stack-order-bf22c245d0be
      const orderedDispatchersData = [...(data.dispatchers || [])].sort((a, b) => {
        const profitA = (a.loadAndProfitsByPeriod && a.loadAndProfitsByPeriod[periodIndex].profit) || 0;
        const profitB = (b.loadAndProfitsByPeriod && b.loadAndProfitsByPeriod[periodIndex].profit) || 0;

        return profitB - profitA;
      });

      orderedDispatchersData?.forEach((dispatcherData, stackIndex) => {
        const profits = dispatcherData?.loadAndProfitsByPeriod?.map(lap => lap.profit);
        const loads = dispatcherData?.loadAndProfitsByPeriod?.map(lap => lap.load);

        const profit = profits ? profits[periodIndex] : 0;
        const load = loads ? loads[periodIndex] : 0;

        (dataForPeriod as any)[stackIndex] = {
          dispatcherId: dispatcherData.dispatcherId,
          profit,
          load,
        };
      });

      return dataForPeriod;
    });
  }, [data]);

  const lastPartsOfColumns = useMemo(() => {
    return chartData.map((periodData: any) => {
      const reversedStackKeys = Object.keys(periodData).filter(stackIndex => periodData[stackIndex].profit).reverse();
      return reversedStackKeys.length ? Number.parseInt(reversedStackKeys[0]) : undefined;
    });
  }, [chartData]);

  const dispatchersBars = useMemo(() => {
    if (!data.dispatchers?.length || !dispatchers) return <></>;

    return data.dispatchers?.map((dispatcherResponse, stackIndex) => {
      const dispatcher = dispatchers.find(d => d.id === dispatcherResponse.dispatcherId);
      if (!dispatcher) return <></>;

      return <Bar
        dataKey={val => val[stackIndex]?.profit}
        name={`${dispatcher.firstName} ${dispatcher.lastName}`}
        stackId='a'
      >
        { chartData.map((periodData: any, periodIndex) => {
          const color = dispatcherStyles[periodData[stackIndex].dispatcherId].color;
          const lastStackIndex = lastPartsOfColumns[periodIndex];
          const radius = stackIndex === lastStackIndex ? [5, 5, 0, 0] : undefined;
          return (
            <Cell
              key={`cell-${stackIndex}`}
              // @ts-ignore
              radius={radius}
              fill={color}
            />
          )
        })}
      </Bar>
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPartsOfColumns, data.dispatchers]);

  const tooltipLabelFormatter = useCallback((label: any, payload: Array<TooltipPayload<number, string>>) => {
    const loads = payload.find(x => x)?.payload[label + loadsSuffix] || 0;
    return `${label} (${loads})`;
  }, []);

  const onExport = async (exportFilters?: WidgetFilters) => {
    const response = await apiFactory(DashboardApi).apiDashboardDispatcherStatisticsExportGet(exportFilters);
    const period = getPeriodFromWidgetFilters(exportFilters);
    FileSaver.saveAs(response!, `Dispatch Statistics ${period}.xlsx`);
    showSuccessToast('Data successfully exported');
  };

  const TooltipContent = (props: TooltipProps<number, string>) => {
    const {
      active,
      payload,
      label,
      labelFormatter,
    } = props;

    if (!(active && payload && payload.length)) {
      return null;
    }

    return (
      <div className='custom-tooltip bg-white p-3' style={{border: '1px solid lightgray'}}>
        <p className='mb-3'>
          {labelFormatter ? labelFormatter(label, payload): `${label} : ${payload[0].value}`}
        </p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {payload.map((pld, index) => {
            const styles = dispatcherStyles[pld.payload[index].dispatcherId];
            if (!styles) {
              return null;
            }

            return (
              <div key={index} style={{color: styles.color, paddingBottom: '0.5rem'}}>
                <span>{styles.name + ' '}</span>
                <span >{pld.value + ' '}</span>
                <span>({pld.payload[index].load || 0})</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return <DashboardPanel>
    <header className='flex'>
        <span className='text-3xl font-medium white-space-nowrap flex justify-content-start align-items-center flex-grow-1 gap-2'>
          Dispatcher statistics
        </span>
      <span className='flex justify-content-end align-items-center flex-grow-1 gap-2'>
          <WidgetToolbar
            value={filters}
            dispatcherState={(state) => state.dashboard.dispatcherState?.dispatcherStatistics}
            onChange={setFilters}
            onExport={onExport}
            onFilter={loadData}
            visibility={{
              period: true,
              from: true,
              to: true,
              dispatchers: true,
              filterButton: true,
            }}
          />
        </span>
    </header>
    <ResponsiveContainer width='100%' height={400}>
      <BarChart data={chartData || []}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='period' interval={0} />
        <YAxis  />
        <Tooltip
          labelFormatter={tooltipLabelFormatter}
          content={TooltipContent}
        />
        <Legend payload={legendPayload}/>
        {dispatchersBars}
      </BarChart>
    </ResponsiveContainer>
  </DashboardPanel>
}

export default DispatchersStatistics;