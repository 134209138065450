/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EStatusHR,
  EVehicleStatus,
  EVehicleStatusFilter,
  Operation,
  UserDto,
  Vehicle,
  VehicleActions,
  VehicleInsuranceOcrResponse,
  VehicleRegistrationOcrResponse,
} from '../models';
import {
    EStatusHRFromJSON,
    EStatusHRToJSON,
    EVehicleStatusFromJSON,
    EVehicleStatusToJSON,
    EVehicleStatusFilterFromJSON,
    EVehicleStatusFilterToJSON,
    OperationFromJSON,
    OperationToJSON,
    UserDtoFromJSON,
    UserDtoToJSON,
    VehicleFromJSON,
    VehicleToJSON,
    VehicleActionsFromJSON,
    VehicleActionsToJSON,
    VehicleInsuranceOcrResponseFromJSON,
    VehicleInsuranceOcrResponseToJSON,
    VehicleRegistrationOcrResponseFromJSON,
    VehicleRegistrationOcrResponseToJSON,
} from '../models';

export interface ApiMobileVehiclesForUserGetRequest {
    id?: number;
    sylectusId?: number;
    owner?: string;
    drivers?: string;
    type?: string;
    vehicleSize?: number;
    status?: EVehicleStatusFilter;
    statusHR?: Array<EStatusHR>;
    available?: string;
    actions?: VehicleActions;
    dispatcher?: string;
    vin?: string;
    userId?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileVehiclesGetRequest {
    id?: number;
    sylectusId?: number;
    owner?: string;
    drivers?: string;
    type?: string;
    vehicleSize?: number;
    status?: EVehicleStatusFilter;
    statusHR?: Array<EStatusHR>;
    available?: string;
    actions?: VehicleActions;
    dispatcher?: string;
    vin?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileVehiclesIdPatchRequest {
    id: number;
    operation?: Array<Operation>;
}

export interface ApiVehiclesAddressZipcodeGetRequest {
    zipcode: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesAllGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesAllUsersGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesCheckVinUniquenessVinGetRequest {
    vin: string;
    vehicleId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesExecuteVehicleStatsGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesForRequestGetRequest {
    requestId?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesGetRequest {
    id?: number;
    sylectusId?: number;
    owner?: string;
    drivers?: string;
    type?: string;
    vehicleSize?: number;
    status?: EVehicleStatusFilter;
    statusHR?: Array<EStatusHR>;
    available?: string;
    actions?: VehicleActions;
    dispatcher?: string;
    vin?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesIdDeleteRequest {
    id: number;
}

export interface ApiVehiclesIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesIdPutRequest {
    id: number;
    vehicle?: Vehicle;
}

export interface ApiVehiclesPostRequest {
    vehicle?: Vehicle;
}

export interface ApiVehiclesUnitFilesAllGetRequest {
    id?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesUnitIdDeleteRequest {
    id: number;
}

export interface ApiVehiclesUnitIdPutRequest {
    id: number;
    vehicle?: Vehicle;
}

export interface ApiVehiclesUnitPostRequest {
    vehicle?: Vehicle;
}

export interface ApiVehiclesUnitStatusPutRequest {
    id?: number;
    statusHr?: EStatusHR;
    status?: EVehicleStatus;
}

export interface ApiVehiclesUnitsGetRequest {
    global?: string;
    statusHR?: EStatusHR;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesVehicleInsuranceToTextFileIdGetRequest {
    fileId: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiVehiclesVehicleRegistrationToTextFileIdGetRequest {
    fileId: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class VehiclesApi extends runtime.BaseAPI {

    /**
     */
    async apiMobileVehiclesForUserGetRaw(requestParameters: ApiMobileVehiclesForUserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Vehicle>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.sylectusId !== undefined) {
            queryParameters['sylectusId'] = requestParameters.sylectusId;
        }

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.drivers !== undefined) {
            queryParameters['drivers'] = requestParameters.drivers;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.vehicleSize !== undefined) {
            queryParameters['vehicleSize'] = requestParameters.vehicleSize;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.statusHR) {
            queryParameters['statusHR'] = requestParameters.statusHR;
        }

        if (requestParameters.available !== undefined) {
            queryParameters['available'] = requestParameters.available;
        }

        if (requestParameters.actions !== undefined) {
            queryParameters['actions'] = requestParameters.actions;
        }

        if (requestParameters.dispatcher !== undefined) {
            queryParameters['dispatcher'] = requestParameters.dispatcher;
        }

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/vehicles/forUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleFromJSON));
    }

    /**
     */
    async apiMobileVehiclesForUserGet(requestParameters: ApiMobileVehiclesForUserGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Vehicle>> {
        const response = await this.apiMobileVehiclesForUserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileVehiclesGetRaw(requestParameters: ApiMobileVehiclesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Vehicle>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.sylectusId !== undefined) {
            queryParameters['sylectusId'] = requestParameters.sylectusId;
        }

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.drivers !== undefined) {
            queryParameters['drivers'] = requestParameters.drivers;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.vehicleSize !== undefined) {
            queryParameters['vehicleSize'] = requestParameters.vehicleSize;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.statusHR) {
            queryParameters['statusHR'] = requestParameters.statusHR;
        }

        if (requestParameters.available !== undefined) {
            queryParameters['available'] = requestParameters.available;
        }

        if (requestParameters.actions !== undefined) {
            queryParameters['actions'] = requestParameters.actions;
        }

        if (requestParameters.dispatcher !== undefined) {
            queryParameters['dispatcher'] = requestParameters.dispatcher;
        }

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/vehicles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleFromJSON));
    }

    /**
     */
    async apiMobileVehiclesGet(requestParameters: ApiMobileVehiclesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Vehicle>> {
        const response = await this.apiMobileVehiclesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileVehiclesIdPatchRaw(requestParameters: ApiMobileVehiclesIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMobileVehiclesIdPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/vehicles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.operation?.map(OperationToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     */
    async apiMobileVehiclesIdPatch(requestParameters: ApiMobileVehiclesIdPatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.apiMobileVehiclesIdPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesAddressZipcodeGetRaw(requestParameters: ApiVehiclesAddressZipcodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.zipcode === null || requestParameters.zipcode === undefined) {
            throw new runtime.RequiredError('zipcode','Required parameter requestParameters.zipcode was null or undefined when calling apiVehiclesAddressZipcodeGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/address/{zipcode}`.replace(`{${"zipcode"}}`, encodeURIComponent(String(requestParameters.zipcode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiVehiclesAddressZipcodeGet(requestParameters: ApiVehiclesAddressZipcodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiVehiclesAddressZipcodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesAllGetRaw(requestParameters: ApiVehiclesAllGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Vehicle>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleFromJSON));
    }

    /**
     */
    async apiVehiclesAllGet(requestParameters: ApiVehiclesAllGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Vehicle>> {
        const response = await this.apiVehiclesAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesAllUsersGetRaw(requestParameters: ApiVehiclesAllUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/all-users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiVehiclesAllUsersGet(requestParameters: ApiVehiclesAllUsersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDto>> {
        const response = await this.apiVehiclesAllUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesCheckVinUniquenessVinGetRaw(requestParameters: ApiVehiclesCheckVinUniquenessVinGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.vin === null || requestParameters.vin === undefined) {
            throw new runtime.RequiredError('vin','Required parameter requestParameters.vin was null or undefined when calling apiVehiclesCheckVinUniquenessVinGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.vehicleId !== undefined) {
            queryParameters['vehicleId'] = requestParameters.vehicleId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/check-vin-uniqueness/{vin}`.replace(`{${"vin"}}`, encodeURIComponent(String(requestParameters.vin))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiVehiclesCheckVinUniquenessVinGet(requestParameters: ApiVehiclesCheckVinUniquenessVinGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apiVehiclesCheckVinUniquenessVinGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesExecuteVehicleStatsGetRaw(requestParameters: ApiVehiclesExecuteVehicleStatsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/executeVehicleStats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiVehiclesExecuteVehicleStatsGet(requestParameters: ApiVehiclesExecuteVehicleStatsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiVehiclesExecuteVehicleStatsGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiVehiclesForRequestGetRaw(requestParameters: ApiVehiclesForRequestGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Vehicle>>> {
        const queryParameters: any = {};

        if (requestParameters.requestId !== undefined) {
            queryParameters['requestId'] = requestParameters.requestId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/forRequest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleFromJSON));
    }

    /**
     */
    async apiVehiclesForRequestGet(requestParameters: ApiVehiclesForRequestGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Vehicle>> {
        const response = await this.apiVehiclesForRequestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesGetRaw(requestParameters: ApiVehiclesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Vehicle>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.sylectusId !== undefined) {
            queryParameters['sylectusId'] = requestParameters.sylectusId;
        }

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.drivers !== undefined) {
            queryParameters['drivers'] = requestParameters.drivers;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.vehicleSize !== undefined) {
            queryParameters['vehicleSize'] = requestParameters.vehicleSize;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.statusHR) {
            queryParameters['statusHR'] = requestParameters.statusHR;
        }

        if (requestParameters.available !== undefined) {
            queryParameters['available'] = requestParameters.available;
        }

        if (requestParameters.actions !== undefined) {
            queryParameters['actions'] = requestParameters.actions;
        }

        if (requestParameters.dispatcher !== undefined) {
            queryParameters['dispatcher'] = requestParameters.dispatcher;
        }

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleFromJSON));
    }

    /**
     */
    async apiVehiclesGet(requestParameters: ApiVehiclesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Vehicle>> {
        const response = await this.apiVehiclesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesIdDeleteRaw(requestParameters: ApiVehiclesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVehiclesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiVehiclesIdDelete(requestParameters: ApiVehiclesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiVehiclesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiVehiclesIdGetRaw(requestParameters: ApiVehiclesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVehiclesIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     */
    async apiVehiclesIdGet(requestParameters: ApiVehiclesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.apiVehiclesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesIdPutRaw(requestParameters: ApiVehiclesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVehiclesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleToJSON(requestParameters.vehicle),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     */
    async apiVehiclesIdPut(requestParameters: ApiVehiclesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.apiVehiclesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesPostRaw(requestParameters: ApiVehiclesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleToJSON(requestParameters.vehicle),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     */
    async apiVehiclesPost(requestParameters: ApiVehiclesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.apiVehiclesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesUnitFilesAllGetRaw(requestParameters: ApiVehiclesUnitFilesAllGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/unit/files/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiVehiclesUnitFilesAllGet(requestParameters: ApiVehiclesUnitFilesAllGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiVehiclesUnitFilesAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesUnitIdDeleteRaw(requestParameters: ApiVehiclesUnitIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVehiclesUnitIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/unit/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiVehiclesUnitIdDelete(requestParameters: ApiVehiclesUnitIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiVehiclesUnitIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiVehiclesUnitIdPutRaw(requestParameters: ApiVehiclesUnitIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiVehiclesUnitIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/unit/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleToJSON(requestParameters.vehicle),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     */
    async apiVehiclesUnitIdPut(requestParameters: ApiVehiclesUnitIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.apiVehiclesUnitIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesUnitPostRaw(requestParameters: ApiVehiclesUnitPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/unit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleToJSON(requestParameters.vehicle),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     */
    async apiVehiclesUnitPost(requestParameters: ApiVehiclesUnitPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.apiVehiclesUnitPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesUnitStatusPutRaw(requestParameters: ApiVehiclesUnitStatusPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Vehicle>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.statusHr !== undefined) {
            queryParameters['statusHr'] = requestParameters.statusHr;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/unit/status`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleFromJSON(jsonValue));
    }

    /**
     */
    async apiVehiclesUnitStatusPut(requestParameters: ApiVehiclesUnitStatusPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Vehicle> {
        const response = await this.apiVehiclesUnitStatusPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesUnitsGetRaw(requestParameters: ApiVehiclesUnitsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Vehicle>>> {
        const queryParameters: any = {};

        if (requestParameters.global !== undefined) {
            queryParameters['global'] = requestParameters.global;
        }

        if (requestParameters.statusHR !== undefined) {
            queryParameters['statusHR'] = requestParameters.statusHR;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/units`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleFromJSON));
    }

    /**
     */
    async apiVehiclesUnitsGet(requestParameters: ApiVehiclesUnitsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Vehicle>> {
        const response = await this.apiVehiclesUnitsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesVehicleInsuranceToTextFileIdGetRaw(requestParameters: ApiVehiclesVehicleInsuranceToTextFileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleInsuranceOcrResponse>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling apiVehiclesVehicleInsuranceToTextFileIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/vehicleInsuranceToText/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleInsuranceOcrResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiVehiclesVehicleInsuranceToTextFileIdGet(requestParameters: ApiVehiclesVehicleInsuranceToTextFileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleInsuranceOcrResponse> {
        const response = await this.apiVehiclesVehicleInsuranceToTextFileIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiVehiclesVehicleRegistrationToTextFileIdGetRaw(requestParameters: ApiVehiclesVehicleRegistrationToTextFileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleRegistrationOcrResponse>> {
        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling apiVehiclesVehicleRegistrationToTextFileIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/vehicles/vehicleRegistrationToText/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleRegistrationOcrResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiVehiclesVehicleRegistrationToTextFileIdGet(requestParameters: ApiVehiclesVehicleRegistrationToTextFileIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleRegistrationOcrResponse> {
        const response = await this.apiVehiclesVehicleRegistrationToTextFileIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
