/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EDispatchStatus = {
    None: 'None',
    NotConfirm: 'NotConfirm',
    InTransitToPickUp: 'InTransitToPickUp',
    Arrived: 'Arrived',
    WaitToGoodToGo: 'WaitToGoodToGo',
    GoodToGo: 'GoodToGo',
    InTransitToDelivery: 'InTransitToDelivery',
    Finished: 'Finished'
} as const;
export type EDispatchStatus = typeof EDispatchStatus[keyof typeof EDispatchStatus];


export function EDispatchStatusFromJSON(json: any): EDispatchStatus {
    return EDispatchStatusFromJSONTyped(json, false);
}

export function EDispatchStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EDispatchStatus {
    return json as EDispatchStatus;
}

export function EDispatchStatusToJSON(value?: EDispatchStatus | null): any {
    return value as any;
}

