import React, { useMemo } from 'react';
import DashboardPanel from '../components/DashboardPanel';
import WidgetToolbar from '../components/WidgetToolbar';
import {
  DashboardApi,
  EWidgetChart,
  EWidgetPeriodType,
} from '../../../generated-api';
import { useAppSelector } from '../../../hooks';
import { apiFactory } from '../../../shared';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, Tooltip, XAxis, YAxis, Cell, TooltipProps } from 'recharts';
import colorPalette from '../components/BidsByHourColorPalette';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';
import ResponsiveContainerWithPadding from '../../../components/ReponsiveContainerWithPadding';
import { Payload as LegendPayload} from 'recharts/types/component/DefaultLegendContent';

const charts = [
  EWidgetChart.Orders,
  EWidgetChart.OrdersLastYear,
  EWidgetChart.BidsByDispatchers,
];

const opacityLevels = ["", "b3", "80"]; // 100%, 70%, 50%

type DispatcherStyle = {
  color: string,
  name: string,
}

function BidsByHour() {
  const { data, filters, loadData, setFilters } = useWidget({
    initialFilters: {
      periodType: 'Day',
      from: today().toDate(),
      to: today().toDate(),
      charts: [EWidgetChart.Orders],
    },
    initialWidgetData: {},
    loadWidgetData: filters => apiFactory(DashboardApi).apiDashboardBidsByHourGet(filters as any),
    usesDispatchers: true,
  });
  const dispatchers = useAppSelector(state => state.dashboard.dispatcherState?.all);

  const dispatcherStyles = useMemo(() => {
    let byId : { [key: string]: DispatcherStyle} = {};

    filters.dispatcherIds?.forEach((dispatcherId, index) => {
      const dispatcher = dispatchers?.find(d => d.id === dispatcherId);
      if (!dispatcher) {
        return;
      }

      const opacity = opacityLevels[Math.floor(index / colorPalette.bidsColors.length) % opacityLevels.length];
      const color = colorPalette.bidsColors[index % (colorPalette.bidsColors.length - 1)] + opacity;
      const name = `${dispatcher.firstName} ${dispatcher.lastName}`;
      byId[dispatcherId] = { color, name };
    });

    return byId;
  }, [filters.dispatcherIds, dispatchers]);

  const legendPayload : LegendPayload[] = useMemo(() => {
    let res : LegendPayload[] = [];
    if (filters.charts?.some(x => x === 'Orders')) {
      res.push({
        color: colorPalette.orders,
        id: 'order',
        value: 'Orders',
        type: 'square',
      });
    }

    if (filters.charts?.some(x => x === 'OrdersLastYear')) {
      res.push({
        color: colorPalette.orders,
        id: 'order',
        value: 'Orders',
        type: 'square',
      });
    }

    if (filters.charts?.some(x => x === 'BidsByDispatchers')) {
      filters.dispatcherIds?.forEach(dispatcherId => {
        const style = dispatcherStyles[dispatcherId];
        res.push({
          color: style.color,
          id: dispatcherId,
          value: style.name,
          type: 'square',
        })
      });
    }

    return res;
  }, [filters.charts, filters.dispatcherIds, dispatcherStyles]);

  const chartData = useMemo(() => {
    const xAxis = data.orders?.length
      ? data.orders.map(x => x.hour)
      : data.ordersLastYear?.length
        ? data.ordersLastYear.map(x => x.hour)
        : data.bidsByHour?.hours || [];

    if (!xAxis.length) return [];

    return xAxis.map((hour, hourIndex) => {
      let hourData = {
        hour,
        order: data.orders?.find(x => x.hour === hour)?.data,
        orderLastYear: data.ordersLastYear?.find(x => x.hour === hour)?.data,
      };

      if (data.bidsByHour?.data?.length) {
        // Ordering inside column is tricky:
        // https://mabdelsattar.medium.com/recharts-stack-order-bf22c245d0be
        const orderedBidsByDispatcher = [...data.bidsByHour.data].sort((a, b) => {
          const dataA = (a.data?.length && a.data[hourIndex]) || 0;
          const dataB = (b.data?.length && b.data[hourIndex]) || 0;

          return dataB - dataA;
        });

        orderedBidsByDispatcher.forEach((dispatcherResponse, stackIndex) => {
          (hourData as any)[stackIndex] = {
            data: (dispatcherResponse.data && dispatcherResponse.data[hourIndex]) || 0,
            dispatcherId: dispatcherResponse.name, // Yes, ID is sent inside name
          };
        });
      }

      return hourData;
    });
  }, [data]);

  const lastPartsOfColumns = useMemo(() => {
    if (!data.bidsByHour?.data?.length)
      return [];

    return chartData.map((hourData : any) => {

      const reversedStackKeys = Object.keys(hourData)
        .filter(key => !isNaN(key as any))
        .filter(key => hourData[key].data)
        .reverse();

      return reversedStackKeys.length ? parseInt(reversedStackKeys[0]) : undefined;
    });
  }, [chartData, data.bidsByHour?.data]);

  const [ordersChart, ordersLastYearChart, bidsByDispatcherChart] = useMemo(() => {
    if (!filters.charts) return [false, false, false];

    return [
      filters.charts.includes(EWidgetChart.Orders),
      filters.charts.includes(EWidgetChart.OrdersLastYear),
      filters.charts.includes(EWidgetChart.BidsByDispatchers),
    ];
  }, [filters.charts]);

  const ordersBar = useMemo(() => {
    if (!ordersChart) return <></>;

    return <Bar dataKey='order' name='Orders' stackId='a' fill={colorPalette.orders} radius={[5, 5, 0, 0]}>
      <LabelList dataKey='order' position='top' fill={colorPalette.orders}/>
    </Bar>;
  }, [ordersChart]);

  const ordersLastYearBar = useMemo(() => {
    if (!ordersLastYearChart) return <></>;

    return <Bar dataKey='orderLastYear' name='Orders (last year)' stackId='b' fill={colorPalette.ordersLastYear} radius={[5, 5, 0, 0]}/>
  }, [ordersLastYearChart]);

  const dispatcherBar = useMemo(() => {
    if (!bidsByDispatcherChart) return <></>;
    if (!data.bidsByHour?.data?.length) return <></>;

    return data.bidsByHour.data.map((dispatcherData, stackIndex) => {
      const dispatcher = dispatchers?.find(d => d.id === dispatcherData.name);
      if (!dispatcher) return <></>;

      return <Bar
        dataKey={val => val[stackIndex]?.data}
        name={`${dispatcher.firstName} ${dispatcher.lastName}`}
        stackId='c'
      >
        { chartData.map((hourData: any, hourIndex) => {
          const color = dispatcherStyles[hourData[stackIndex].dispatcherId].color;
          const lastStackIndex = lastPartsOfColumns[hourIndex];
          const radius = stackIndex === lastStackIndex ? [5, 5, 0, 0] : undefined;
          return (
            <Cell
              key={`cell-${stackIndex}`}
              // @ts-ignore
              radius={radius}
              fill={color}
            />
          )
        })}
      </Bar>
    });
  }, [
    bidsByDispatcherChart,
    lastPartsOfColumns,
    dispatcherStyles,
    dispatchers,
    chartData,
    data.bidsByHour?.data,
  ]);

  const TooltipContent = (props: TooltipProps<number, string>) => {
    const {
      active,
      payload,
      label,
    } = props;

    if (!(active && payload && payload.length)) {
      return null;
    }

    const nonDispatchersBarsCount = filters.charts?.filter(x => x !== 'BidsByDispatchers')?.length || 0;
    return (
      <div className='custom-tooltip bg-white p-3' style={{border: '1px solid lightgray'}}>
        <p className='mb-3'>
          {label}
        </p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {payload.map((pld, index) => {
            let styles : DispatcherStyle;
            if (pld.dataKey === 'order' || pld.dataKey === 'orderLastYear') {
              styles = {
                name: pld.name!,
                color: pld.color!
              }
            } else {
              const fixedIndex = index - nonDispatchersBarsCount;
              const dispatcherId = pld.payload[fixedIndex].dispatcherId;
              if (!dispatcherId) {
                console.error(`missing dispatcherId: ${index}, ${nonDispatchersBarsCount} ${pld.payload}`);
                return null;
              }
              styles = dispatcherStyles[dispatcherId];
              if (!styles) {
                console.error(`styles missing for ${pld.payload[fixedIndex].dispatcherId}`);
                return null;
              }
            }

            return (
              <div key={index} style={{color: styles.color, paddingBottom: '0.5rem'}}>
                <span>{styles.name + ': '}</span>
                <span >{pld.value + ' '}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <DashboardPanel>
      <header className='flex'>
        <span className='text-3xl font-medium white-space-nowrap flex justify-content-start align-items-center flex-grow-1 gap-2'>
          Bids by hour
        </span>
        <span className='flex justify-content-end align-items-center flex-grow-1 gap-2'>
          <WidgetToolbar
            value={filters}
            onChange={setFilters}
            onFilter={loadData}
            charts={charts}
            visibility={{
              period: true,
              from: true,
              to: true,
              dispatchers: bidsByDispatcherChart,
              filterButton: true,
              options: true,
            }}
          />
        </span>
      </header>
      <ResponsiveContainerWithPadding width='100%' height={400}>
        <BarChart data={chartData || []}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='hour' interval={0} tick={CustomizedGroupTick}/>
          <YAxis domain={filters.periodType === EWidgetPeriodType.Month ? [0, 'dataMax + 500'] : undefined} />
          <Tooltip content={TooltipContent}/>
          <Legend payload={legendPayload}/>
          {ordersBar}
          {ordersLastYearBar}
          {dispatcherBar}
        </BarChart>
      </ResponsiveContainerWithPadding>
    </DashboardPanel>
  );
}

const CustomizedGroupTick = (props: any) => {
  const {x, y, payload } = props;
  const [from, to] = (payload.value as string).split(' - ');

  return (
    <g>
      <text x={x - 20} y={y + 9} fontSize='0.8rem' textAnchor='start'>
        {from + '-'}
      </text>
      <text x={x - 20} y={y + 20} fontSize='0.8rem' textAnchor='start'>
        {to}
      </text>
    </g>
  );
};

export default BidsByHour;