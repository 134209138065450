import styled from '@emotion/styled';
import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { BrokerageInformationResponse, DashboardApi } from '../../../generated-api';
import { apiFactory } from '../../../shared';
import { nameof } from 'ts-simple-nameof';
import DashboardPanel from '../components/DashboardPanel';
import WidgetToolbar, { WidgetFilters } from '../components/WidgetToolbar';
import { useAppSelector } from '../../../hooks';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';
import { getPeriodFromWidgetFilters } from './utils/widgets-utils';
import FileSaver from 'file-saver';
import { showSuccessToast } from '../../../components/LogisticsToast';

function BrokerageInformation() {
  const brokerages = useAppSelector(state => state.dashboard.brokerages);

  const { data, filters, loadData, setFilters } = useWidget({
    initialFilters: {
      periodType: 'Day',
      from: today().toDate(),
      to: today().toDate(),
      brokeragesIds: brokerages?.filter(x => x.id).map(x => x.id!) || [],
    },
    initialWidgetData: [],
    loadWidgetData: filters => apiFactory(DashboardApi)
      .apiDashboardBrokerageInformationPost({ widgetParameters: filters }),
    usesBrokerages: true,
  });

  const onExport = async (exportFilters?: WidgetFilters) => {
    const response = await apiFactory(DashboardApi).apiDashboardBrokerageInformationExportPost({widgetParameters: exportFilters});
    const period = getPeriodFromWidgetFilters(exportFilters);
    FileSaver.saveAs(response!, `Brokerage Information ${period}.xlsx`);
    showSuccessToast('Data successfully exported');
  };

  return <StyledDashboardPanel>
    <header className='flex flex-wrap'>
      <span className='text-3xl font-medium white-space-nowrap flex justify-content-start flex-grow-1 gap-2'>
        Brokerage Information
      </span>
      <div className='flex max-w-full justify-content-end align-items-center flex-grow-1 gap-2'>
        <WidgetToolbar
          value={filters}
          onChange={setFilters}
          onFilter={loadData}
          onExport={onExport}
          visibility={{
            period: true,
            from: true,
            to: true,
            brokerages: true,
            filterButton: true,
          }}
        />
      </div>
    </header>
    <div className='flex flex-column pt-3'>
      <StyledTable
        dataSource={data}
        columns={columns}
        pagination={false}
        size='small'
        bordered
        summary={(pageData : readonly BrokerageInformationResponse[]) => {
          let totalProfit = 0;
          pageData.forEach(({ profit }) => {
            totalProfit += profit || 0;
          });

          const totalLoads = pageData.reduce(
            (total, current) => total + (current.loads || 0), 0
          );

          return <>
            <Table.Summary.Row>
              <Table.Summary.Cell align='center' index={0}>
                <b>All</b>
                </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={1}>
                <b>${totalProfit}</b>
              </Table.Summary.Cell>
              <Table.Summary.Cell align='center' index={1}>
                <b>{totalLoads}</b>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        }}
      />
    </div>
  </StyledDashboardPanel>;
}

const StyledDashboardPanel = styled(DashboardPanel)`
  & {
    width: 50%;
  }
`;

const StyledTable = styled(Table)`
  & {
    height: 400px;
    overflow: auto;
  }
`

const columns: ColumnType<BrokerageInformationResponse>[] = [
  {
    title: 'Brokerage',
    dataIndex: nameof<BrokerageInformationResponse>(x => x.brokerage),
    key: nameof<BrokerageInformationResponse>(x => x.brokerage),
    align: 'center',
    width: 200,
    sorter: (a, b) => a.brokerage!.localeCompare(b.brokerage!),
  },
  {
    title: 'Profit',
    dataIndex: nameof<BrokerageInformationResponse>(x => x.profit),
    key: nameof<BrokerageInformationResponse>(x => x.profit),
    align: 'center',
    width: 200,
    render: (data: number) => {
      return '$' + Math.trunc(data || 0);
    },
    sorter: (a, b) => a.profit! - b.profit!,
    defaultSortOrder: 'descend',
  },
  {
    title: 'Loads',
    dataIndex: nameof<BrokerageInformationResponse>(x => x.loads),
    key: nameof<BrokerageInformationResponse>(x => x.loads),
    align: 'center',
    width: 200,
    sorter: (a, b) => a.loads! - b.loads!,
  },
];

export default BrokerageInformation;
