import React, { useMemo } from 'react';
import DashboardPanel from '../components/DashboardPanel';
import WidgetToolbar from '../components/WidgetToolbar';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import {
  DashboardApi,
  EWidgetChart,
  EWidgetPeriodType,
} from '../../../generated-api';
import { apiFactory } from '../../../shared';
import colorPalette from '../components/ProfitStatisticsColorPalette';
import useWidget from '../hooks/WidgetHook';
import { today } from '../../../shared/util/dateUtils';
import ResponsiveContainerWithPadding from '../../../components/ReponsiveContainerWithPadding';

const charts = [
  EWidgetChart.Profit,
  EWidgetChart.DriversProfit,
  EWidgetChart.Loads,
  EWidgetChart.Margin
];

function ProfitStatistics() {
  const { data, filters, loadData, setFilters } = useWidget({
    initialFilters: {
      periodType: 'Week',
      from: today().add(-11, 'week').toDate(),
      to: today().toDate(),
      charts: charts,
    },
    initialWidgetData: {},
    loadWidgetData: filters => apiFactory(DashboardApi)
      .apiDashboardProfitStatisticsGet(filters),
  })
  const joinedChartData = useMemo(() => {
    if (!data?.period?.length) return [];

    return data.period.map((period, index) => {
      const driversProfit = data.driversProfit?.at(index) || 0;
      const profit = data.profit?.at(index) || 0;
      const margin = data.margin?.at(index) || 0;
      const loads = data.loads?.at(index) || 0;

      return { period, driversProfit, profit, margin, loads };
    })
  }, [data]);

  const [
    driversProfitChart,
    profitChart,
    marginChart,
    loadsChart,
  ] = useMemo(() => {
    if (!filters.charts) return [false, false, false, false];

    return [
      filters.charts.includes(EWidgetChart.DriversProfit),
      filters.charts.includes(EWidgetChart.Profit),
      filters.charts.includes(EWidgetChart.Margin),
      filters.charts.includes(EWidgetChart.Loads),
    ];
  }, [filters.charts]);

  const driversProfitBar = useMemo(() => {
    if (!driversProfitChart) return <></>;
    const labels = profitChart ? <></> : <LabelList dataKey='driversProfit' position='top' offset={5} fill={colorPalette.payment}/>;

    return <Bar dataKey='driversProfit' name='Payment' stackId='a' fill={colorPalette.payment}>
      {labels}
      {
        joinedChartData.map((entry, index) => {
          const isRadius = Number(entry.profit) < 1 || !profitChart;
          return (
            // @ts-ignore
            <Cell key={`cell-${index}`} radius={isRadius ? [5, 5, 0, 0] : undefined} />
          )
        })
      }
    </Bar>;
  }, [driversProfitChart, profitChart, joinedChartData]);

  const profitBar = useMemo(() => {
    if (!profitChart) return <></>;
    const labels = driversProfitChart ? <>
      <LabelList dataKey='driversProfit' position='top' offset={5} fill={colorPalette.payment}/>
      <LabelList dataKey='profit' position='top' offset={20} fill={colorPalette.profit} fontWeight='bold'/>
    </> : <LabelList dataKey='profit' position='top' offset={5} fill={colorPalette.profit} fontWeight='bold'/>;

    return <Bar dataKey='profit' name='Profit' stackId='a' fill={colorPalette.profit} radius={[5, 5, 0, 0]}>
      {labels}
    </Bar>;
  }, [profitChart, driversProfitChart]);

  const marginBar = useMemo(() => {
    if (!marginChart) return <></>;
    const labels = loadsChart ? <></> : <LabelList dataKey='margin' position='top' offset={5} fill={colorPalette.margin}/>;

    return <Bar dataKey='margin' name='Margin' stackId='b' fill={colorPalette.margin}>
      {labels}
      {
        joinedChartData.map((entry, index) => {
          const isRadius = Number(entry.loads) < 1 || !loadsChart;
          return (
            // @ts-ignore
            <Cell key={`cell-${index}`} radius={isRadius ? [5, 5, 0, 0] : undefined} />
          )
        })
      }
    </Bar>;
  }, [marginChart, loadsChart, joinedChartData]);

  const loadsBar = useMemo(() => {
    if (!loadsChart) return <></>;
    const labels = marginChart ? <>
      <LabelList dataKey='margin' position='top' offset={5} fill={colorPalette.margin}/>
      <LabelList dataKey='loads' position='top' offset={20} fill={colorPalette.loads}/>
    </> : <LabelList dataKey='loads' position='top' offset={5} fill={colorPalette.loads}/>;

    return <Bar dataKey='loads' name='Loads' stackId='b' fill={colorPalette.loads} radius={[5, 5, 0, 0]}>
      {labels}
    </Bar>;
  }, [loadsChart, marginChart]);

  return <DashboardPanel>
    <header className='flex'>
        <span className='text-3xl font-medium white-space-nowrap flex justify-content-start align-items-center flex-grow-1 gap-2'>
          Profit statistics
        </span>
      <span className='flex justify-content-end align-items-center flex-grow-1 gap-2'>
          <WidgetToolbar
            value={filters}
            onChange={setFilters}
            onFilter={loadData}
            charts={charts}
            visibility={{
              period: true,
              from: true,
              to: true,
              filterButton: true,
              options: true,
            }}
          />
        </span>
    </header>
    <ResponsiveContainerWithPadding width='100%' height={400}>
      <BarChart data={joinedChartData || []}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='period' interval={0} />
        <YAxis domain={filters.periodType === EWidgetPeriodType.Month ? [0, 'dataMax + 50000'] : undefined} tickCount={10} />
        <Tooltip />
        <Legend />
        {driversProfitBar}
        {profitBar}
        {marginBar}
        {loadsBar}
      </BarChart>
    </ResponsiveContainerWithPadding>
  </DashboardPanel>
}

export default ProfitStatistics;
