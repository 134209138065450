/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bid } from './Bid';
import {
    BidFromJSON,
    BidFromJSONTyped,
    BidToJSON,
} from './Bid';
import type { Brokerage } from './Brokerage';
import {
    BrokerageFromJSON,
    BrokerageFromJSONTyped,
    BrokerageToJSON,
} from './Brokerage';
import type { LogisticsFile } from './LogisticsFile';
import {
    LogisticsFileFromJSON,
    LogisticsFileFromJSONTyped,
    LogisticsFileToJSON,
} from './LogisticsFile';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';
import type { Tracking } from './Tracking';
import {
    TrackingFromJSON,
    TrackingFromJSONTyped,
    TrackingToJSON,
} from './Tracking';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface ListOfLoads
 */
export interface ListOfLoads {
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    number?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoads
     */
    payed?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    gross?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    syl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    r?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ListOfLoads
     */
    createtime?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    vehicleId?: number | null;
    /**
     * 
     * @type {Vehicle}
     * @memberof ListOfLoads
     */
    vehicle?: Vehicle;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    dFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    dLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    pickUpAtState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    pickUpAtCity?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    pickUpAtIndex?: number | null;
    /**
     * 
     * @type {Point}
     * @memberof ListOfLoads
     */
    pickUpAtPoint?: Point;
    /**
     * 
     * @type {Date}
     * @memberof ListOfLoads
     */
    pickUpDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    deliverToState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    deliverToCity?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    deliverToIndex?: number | null;
    /**
     * 
     * @type {Point}
     * @memberof ListOfLoads
     */
    deliverToPoint?: Point;
    /**
     * 
     * @type {Date}
     * @memberof ListOfLoads
     */
    deliveryDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    userFullName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    miles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    priceForDriver?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    profit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    profitPercent?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    note?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoads
     */
    factoring?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    textWeek?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ListOfLoads
     */
    week?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ListOfLoads
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ListOfLoads
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof ListOfLoads
     */
    boLFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof ListOfLoads
     */
    poDFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {Array<LogisticsFile>}
     * @memberof ListOfLoads
     */
    pictureFreightFile?: Array<LogisticsFile> | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    bidId?: number | null;
    /**
     * 
     * @type {Bid}
     * @memberof ListOfLoads
     */
    bid?: Bid;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    brokerageId?: number | null;
    /**
     * 
     * @type {Brokerage}
     * @memberof ListOfLoads
     */
    brokerage?: Brokerage;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    userId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof ListOfLoads
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    pickUpAtCompany?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    deliveryCompany?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    pieces?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    boxDims?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListOfLoads
     */
    weight?: string | null;
    /**
     * 
     * @type {Tracking}
     * @memberof ListOfLoads
     */
    tracking?: Tracking;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    trackingId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    eta?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    milesForPickUp?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListOfLoads
     */
    quickPay?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ListOfLoads
     */
    id?: number;
}

/**
 * Check if a given object implements the ListOfLoads interface.
 */
export function instanceOfListOfLoads(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListOfLoadsFromJSON(json: any): ListOfLoads {
    return ListOfLoadsFromJSONTyped(json, false);
}

export function ListOfLoadsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOfLoads {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'payed': !exists(json, 'payed') ? undefined : json['payed'],
        'gross': !exists(json, 'gross') ? undefined : json['gross'],
        'syl': !exists(json, 'syl') ? undefined : json['syl'],
        'r': !exists(json, 'r') ? undefined : json['r'],
        'createtime': !exists(json, 'createtime') ? undefined : (json['createtime'] === null ? null : new Date(json['createtime'])),
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleFromJSON(json['vehicle']),
        'dFirstName': !exists(json, 'dFirstName') ? undefined : json['dFirstName'],
        'dLastName': !exists(json, 'dLastName') ? undefined : json['dLastName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'pickUpAtState': !exists(json, 'pickUpAtState') ? undefined : json['pickUpAtState'],
        'pickUpAtCity': !exists(json, 'pickUpAtCity') ? undefined : json['pickUpAtCity'],
        'pickUpAtIndex': !exists(json, 'pickUpAtIndex') ? undefined : json['pickUpAtIndex'],
        'pickUpAtPoint': !exists(json, 'pickUpAtPoint') ? undefined : PointFromJSON(json['pickUpAtPoint']),
        'pickUpDate': !exists(json, 'pickUpDate') ? undefined : (json['pickUpDate'] === null ? null : new Date(json['pickUpDate'])),
        'deliverToState': !exists(json, 'deliverToState') ? undefined : json['deliverToState'],
        'deliverToCity': !exists(json, 'deliverToCity') ? undefined : json['deliverToCity'],
        'deliverToIndex': !exists(json, 'deliverToIndex') ? undefined : json['deliverToIndex'],
        'deliverToPoint': !exists(json, 'deliverToPoint') ? undefined : PointFromJSON(json['deliverToPoint']),
        'deliveryDate': !exists(json, 'deliveryDate') ? undefined : (json['deliveryDate'] === null ? null : new Date(json['deliveryDate'])),
        'userFullName': !exists(json, 'userFullName') ? undefined : json['userFullName'],
        'miles': !exists(json, 'miles') ? undefined : json['miles'],
        'priceForDriver': !exists(json, 'priceForDriver') ? undefined : json['priceForDriver'],
        'profit': !exists(json, 'profit') ? undefined : json['profit'],
        'profitPercent': !exists(json, 'profitPercent') ? undefined : json['profitPercent'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'factoring': !exists(json, 'factoring') ? undefined : json['factoring'],
        'textWeek': !exists(json, 'textWeek') ? undefined : json['textWeek'],
        'week': !exists(json, 'week') ? undefined : (json['week'] === null ? null : new Date(json['week'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'boLFile': !exists(json, 'boLFile') ? undefined : (json['boLFile'] === null ? null : (json['boLFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'poDFile': !exists(json, 'poDFile') ? undefined : (json['poDFile'] === null ? null : (json['poDFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'pictureFreightFile': !exists(json, 'pictureFreightFile') ? undefined : (json['pictureFreightFile'] === null ? null : (json['pictureFreightFile'] as Array<any>).map(LogisticsFileFromJSON)),
        'bidId': !exists(json, 'bidId') ? undefined : json['bidId'],
        'bid': !exists(json, 'bid') ? undefined : BidFromJSON(json['bid']),
        'brokerageId': !exists(json, 'brokerageId') ? undefined : json['brokerageId'],
        'brokerage': !exists(json, 'brokerage') ? undefined : BrokerageFromJSON(json['brokerage']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'pickUpAtCompany': !exists(json, 'pickUpAtCompany') ? undefined : json['pickUpAtCompany'],
        'deliveryCompany': !exists(json, 'deliveryCompany') ? undefined : json['deliveryCompany'],
        'pieces': !exists(json, 'pieces') ? undefined : json['pieces'],
        'boxDims': !exists(json, 'boxDims') ? undefined : json['boxDims'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'tracking': !exists(json, 'tracking') ? undefined : TrackingFromJSON(json['tracking']),
        'trackingId': !exists(json, 'trackingId') ? undefined : json['trackingId'],
        'eta': !exists(json, 'eta') ? undefined : json['eta'],
        'milesForPickUp': !exists(json, 'milesForPickUp') ? undefined : json['milesForPickUp'],
        'quickPay': !exists(json, 'quickPay') ? undefined : json['quickPay'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ListOfLoadsToJSON(value?: ListOfLoads | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'payed': value.payed,
        'gross': value.gross,
        'syl': value.syl,
        'r': value.r,
        'createtime': value.createtime === undefined ? undefined : (value.createtime === null ? null : value.createtime.toISOString()),
        'vehicleId': value.vehicleId,
        'vehicle': VehicleToJSON(value.vehicle),
        'dFirstName': value.dFirstName,
        'dLastName': value.dLastName,
        'companyName': value.companyName,
        'pickUpAtState': value.pickUpAtState,
        'pickUpAtCity': value.pickUpAtCity,
        'pickUpAtIndex': value.pickUpAtIndex,
        'pickUpAtPoint': PointToJSON(value.pickUpAtPoint),
        'pickUpDate': value.pickUpDate === undefined ? undefined : (value.pickUpDate === null ? null : value.pickUpDate.toISOString()),
        'deliverToState': value.deliverToState,
        'deliverToCity': value.deliverToCity,
        'deliverToIndex': value.deliverToIndex,
        'deliverToPoint': PointToJSON(value.deliverToPoint),
        'deliveryDate': value.deliveryDate === undefined ? undefined : (value.deliveryDate === null ? null : value.deliveryDate.toISOString()),
        'userFullName': value.userFullName,
        'miles': value.miles,
        'priceForDriver': value.priceForDriver,
        'profit': value.profit,
        'profitPercent': value.profitPercent,
        'note': value.note,
        'factoring': value.factoring,
        'textWeek': value.textWeek,
        'week': value.week === undefined ? undefined : (value.week === null ? null : value.week.toISOString().substr(0,10)),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'boLFile': value.boLFile === undefined ? undefined : (value.boLFile === null ? null : (value.boLFile as Array<any>).map(LogisticsFileToJSON)),
        'poDFile': value.poDFile === undefined ? undefined : (value.poDFile === null ? null : (value.poDFile as Array<any>).map(LogisticsFileToJSON)),
        'pictureFreightFile': value.pictureFreightFile === undefined ? undefined : (value.pictureFreightFile === null ? null : (value.pictureFreightFile as Array<any>).map(LogisticsFileToJSON)),
        'bidId': value.bidId,
        'bid': BidToJSON(value.bid),
        'brokerageId': value.brokerageId,
        'brokerage': BrokerageToJSON(value.brokerage),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'pickUpAtCompany': value.pickUpAtCompany,
        'deliveryCompany': value.deliveryCompany,
        'pieces': value.pieces,
        'boxDims': value.boxDims,
        'weight': value.weight,
        'tracking': TrackingToJSON(value.tracking),
        'trackingId': value.trackingId,
        'eta': value.eta,
        'milesForPickUp': value.milesForPickUp,
        'quickPay': value.quickPay,
        'id': value.id,
    };
}

