/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopVehiclesResponse
 */
export interface TopVehiclesResponse {
    /**
     * 
     * @type {string}
     * @memberof TopVehiclesResponse
     */
    dispatcher?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TopVehiclesResponse
     */
    vehiclesTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TopVehiclesResponse
     */
    vehiclesInService?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TopVehiclesResponse
     */
    vehiclesUsed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TopVehiclesResponse
     */
    percentageInService?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TopVehiclesResponse
     */
    percentageUsed?: number | null;
}

/**
 * Check if a given object implements the TopVehiclesResponse interface.
 */
export function instanceOfTopVehiclesResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopVehiclesResponseFromJSON(json: any): TopVehiclesResponse {
    return TopVehiclesResponseFromJSONTyped(json, false);
}

export function TopVehiclesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopVehiclesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dispatcher': !exists(json, 'dispatcher') ? undefined : json['dispatcher'],
        'vehiclesTotal': !exists(json, 'vehiclesTotal') ? undefined : json['vehiclesTotal'],
        'vehiclesInService': !exists(json, 'vehiclesInService') ? undefined : json['vehiclesInService'],
        'vehiclesUsed': !exists(json, 'vehiclesUsed') ? undefined : json['vehiclesUsed'],
        'percentageInService': !exists(json, 'percentageInService') ? undefined : json['percentageInService'],
        'percentageUsed': !exists(json, 'percentageUsed') ? undefined : json['percentageUsed'],
    };
}

export function TopVehiclesResponseToJSON(value?: TopVehiclesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dispatcher': value.dispatcher,
        'vehiclesTotal': value.vehiclesTotal,
        'vehiclesInService': value.vehiclesInService,
        'vehiclesUsed': value.vehiclesUsed,
        'percentageInService': value.percentageInService,
        'percentageUsed': value.percentageUsed,
    };
}

