import { memo } from 'react';
import LoadedOrderDataBlock from '../../../components/LoadedOrderDataBlock';
import LoadedOrderVehiclesBlock from '../../../components/LoadedOrderVehiclesBlock';
import LogisticsDialog from '../../../components/LogisticsDialog';
import PricesBlock from '../../../components/prices/PricesBlock';
import { BidEditData, BidEditParameters, useBidEdit } from './hook';
import BidReplyViewer from '../BidReplyViewer';
import { Button } from 'primereact/button';
import CenteredSpinner from '../../../components/CenteredSpinner';
import { BrowserView, MobileView } from 'react-device-detect';
import BidCheckboxes from '../../loaded-orders/BidCheckboxes';


const BidEdit = memo((props: BidEditParameters) => {
  const form = useBidEdit(props);
  return (
    <>
      {form.listOfLoadsEdit}
      {form.trackingEdit}
      <LogisticsDialog
        visible={props.visible}
        onHide={form.handleOnHide}
        dismissableMask
        className='full-height-dialog w-11'
      >
        <BrowserView className='flex flex-row h-full'>
          {OrderBlock(form)}
          {BidMainBlock(form)}
        </BrowserView>
        <MobileView>
          {OrderBlock(form)}
          {BidMainBlock(form)}
        </MobileView>
        <CenteredSpinner visible={form.stateLoading} />
      </LogisticsDialog>
    </>
  );
}, (prev, next) => prev.visible === next.visible);

function BidMainBlock(form : BidEditData) {
  return <>
    <BrowserView className='flex-grow-1 overflow-y-auto overflow-x-hidden'>
      <LoadedOrderVehiclesBlock
        data={[form.selectedUnit!]}
        selected={{ ...form.selectedUnit }}
        onSelectionChange={form.setSelectedUnit}
        extraHeaderContent={form.extraLinks} />
      <div className='grid'>
        <div className='col-12 text-2xl font-bold'>
          <div className='bid-edit-state-spacer'>
            <span>State: {form.data?.status?.name}</span>
          </div>
          <span className='ml-1'>Broker: {form.data.loadedOrder?.contactEmail}</span>
        </div>
      </div>
      <div className='grid'>
        <div className='col-4'>
          <p>
            <span className='text-2xl'>Bid settings</span>
          </p>
          <PricesBlock 
            onPricesChange={form.changePrices}
            minProfit={form.systemSettings.minProfit}
            data={form.pricesData} />
          <BidCheckboxes formBid={form.data} setFormBid={form.setData} />
        </div>
        <div className='col-8'>
          <div className='h-30rem'>
            {/* <MailEditor
            disabled
            rawTemplate={data?.brokerText || ''}
            variables={{
              Eta: data.loadedOrder?.eta || '',
              Dimensions: data.loadedOrder?.dimensions || '',
              PayLoad: selectedUnit?.vehicle?.payload?.toString() || '',
              McNumber: systemSettings?.mcNumber?.toString() || '',
              Miles: data.loadedOrder?.miles?.toString() || '',
              PriceForBroker: pricesData.priceForBroker?.toString() || '',
              DockHigh: data.dockHigh ? 'Dock high' : '',
              TtDrivers: data.ttDrivers ? 'TRUE TEAM DRIVERS' : '',
              LiftGate: data.liftGate ? 'Lift gate' : '',
              PalletJack: data.palletJack ? 'Pallet jack' : '',
              DriverAssist: data.driverAssist ? 'Driver assist' : '',
              FullPpe: data.fullPPE ? 'FULL PPE' : '',
            }}
            onRawTemplateChange={emptyArrowFunction}
            onFilledTemplateChange={emptyArrowFunction}
          /> */}
            <BidReplyViewer bidReplies={form.data.bidReplies} bid={form.data} />
          </div>
          <div className='flex justify-content-between pt-4'>
            <div className="justify-content-start">
              <Button label='ACCEPT' className='p-button-success min-w-min w-8rem h-4rem mr-5'
                      onClick={() => form.lolModal.show({})} disabled={!!form.data?.listOfLoads?.length}
              ></Button>
            </div>
            <Button label='CLOSE' className='p-button-secondary min-w-min w-8rem h-4rem'
              onClick={form.handleOnHide}></Button>
          </div>
        </div>
      </div>
    </BrowserView>
    <MobileView>
      <div style={{overflowY: "scroll" }}>
        <LoadedOrderVehiclesBlock
          data={[form.selectedUnit!]}
          selected={{ ...form.selectedUnit }}
          onSelectionChange={form.setSelectedUnit}
          extraHeaderContent={form.extraLinks} />
      </div>
      <div className='text-base font-bold'>
        <span>State: {form.data?.status?.name}</span>
      </div>
      <span className='text-base font-bold'>Broker: {form.data.loadedOrder?.contactEmail}</span>
      <p>
        <span className='text-2xl'>Bid settings</span>
      </p>
      <PricesBlock 
        onPricesChange={form.changePrices}
        minProfit={form.systemSettings.minProfit}
        data={form.pricesData} />
      <BidCheckboxes formBid={form.data} setFormBid={form.setData} />
      <div className='h-30rem'>
        {/* <MailEditor
        disabled
        rawTemplate={data?.brokerText || ''}
        variables={{
          Eta: data.loadedOrder?.eta || '',
          Dimensions: data.loadedOrder?.dimensions || '',
          PayLoad: selectedUnit?.vehicle?.payload?.toString() || '',
          McNumber: systemSettings?.mcNumber?.toString() || '',
          Miles: data.loadedOrder?.miles?.toString() || '',
          PriceForBroker: pricesData.priceForBroker?.toString() || '',
          DockHigh: data.dockHigh ? 'Dock high' : '',
          TtDrivers: data.ttDrivers ? 'TRUE TEAM DRIVERS' : '',
          LiftGate: data.liftGate ? 'Lift gate' : '',
          PalletJack: data.palletJack ? 'Pallet jack' : '',
          DriverAssist: data.driverAssist ? 'Driver assist' : '',
          FullPpe: data.fullPPE ? 'FULL PPE' : '',
        }}
        onRawTemplateChange={emptyArrowFunction}
        onFilledTemplateChange={emptyArrowFunction}
      /> */}
        <BidReplyViewer bidReplies={form.data.bidReplies} bid={form.data} />
      </div>
      <div className='flex justify-content-between pt-4 pb-4'>
        <Button label='LIST OF LOADS' className='p-button-success min-w-min w-8rem h-4rem'
          onClick={() => form.lolModal.show({})} disabled={!!form.data?.listOfLoads?.length}></Button>
        <Button label='TRACKING' className='p-button-info min-w-min w-8rem h-4rem'
          onClick={() => form.trackingModal.show({})} disabled={!!form.data?.trackings?.length}></Button>
        <Button label='CLOSE' className='p-button-secondary min-w-min w-8rem h-4rem'
          onClick={form.handleOnHide}></Button>
      </div>
    </MobileView>
  </>;
}

function OrderBlock(form : BidEditData) {
  return <>
    <BrowserView className='text-base' style={{ flexBasis: '25%', maxWidth: '25%' }}>
      <div className='text-2xl font-bold'>
        Bid for #{form.data?.loadedOrder?.orderNumber}
      </div>
      <div className='text-lg mt-2 logistics-main-date'>
        Created {form.formatTime(form.data?.createdDate)}
      </div>
      <LoadedOrderDataBlock {...form.data!.loadedOrder} />
    </BrowserView>
    <MobileView>
      <div className='flex justify-content-between align-items-baseline flex-wrap'>
        <div className='text-xl font-bold'>
          Bid for #{form.data?.loadedOrder?.orderNumber}
        </div>
        <div className='text-base text-right mt-2 logistics-main-date'>
          Created {form.formatTime(form.data?.createdDate)}
        </div>
        <LoadedOrderDataBlock {...form.data!.loadedOrder} />
      </div>
    </MobileView>
  </>;
}

export default BidEdit;