import { RootState } from "../../store/store";
import listOfLoadsColumns from "./ListOfLoadsColumnDef";
import { useModal } from '../../hooks';
import { ApiListOfLoadsGetRequest, ListOfLoads, ListOfLoadsApi } from '../../generated-api';
import LogisticsDataGrid from '../../components/data-grid/LogisticsDataGrid';
import serverFilterComponent, { serverFilterFunctionName } from '../../components/filters/server/ServerFilter';
import { getListOfLoadsPage, setEntitiesFieldsWithAction } from "./ListOfLoadsSlice";
import ListOfLoadsEdit from './ListOfLoadsEdit';
import ListOfLoadsCreate from './ListOfLoadsCreate';
import ListOfLoadsExport from './ListOfLoadsExport';
import ListOfLoadsChangelog from './ListOfLoadsChangelog';
import { Button } from 'primereact/button';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dayjs from 'dayjs';
import { classNames } from 'primereact/utils';
import { apiFactory } from '../../shared';
import { useAppDispatch } from '../../hooks';
import GlobalFilter from '../../components/filters/server/GlobalFilter';
import { ActionMenuItem } from '../../components/data-grid/actions/ActionMenu';
import { RowActionItem } from '../../components/data-grid/actions/RowActionButton';
import { logisticsConfirmDialog } from '../../components/LogisticsConfifmDialog';
import useRights from '../../hooks/RightsHook';
import { useCallback, useMemo } from 'react';
import ListOfLoadsOwnerBill from './ListOfLoadsOwnerBill';
import VisibilityToggler from '../../components/VisibilityToggler';

const selectState = (state: RootState) => state.listOfLoads;

function ListOfLoadsList() {
  const dispatch = useAppDispatch();
  const rights = useRights(security => security.listOfLoads);
  const lolEditModal = useModal<ListOfLoads>({});
  const lolCreateModal = useModal<ListOfLoads>({});
  const lolExportModal = useModal(undefined);
  const lolOwnerBillModal = useModal(undefined);
  const changelogModal = useModal<number | undefined>(undefined);

  const listOfLoadsColumnActionItems = useCallback((row : ListOfLoads) => {
      let actions : RowActionItem<ListOfLoads>[] = [];

      const payed : RowActionItem<ListOfLoads> = {
        label: 'Driver payed',
        icon: 'pi pi-dollar',
        command: rowData => {
          if (!rowData?.id) return;

          const listOfLoadsToSave = {
            ...rowData,
            payed: !rowData.payed,
          };

          apiFactory(ListOfLoadsApi)
            .apiListOfLoadsPayedPut({ id: rowData.id, payed: !rowData.payed })
            .then(() => dispatch(setEntitiesFieldsWithAction([listOfLoadsToSave])));
        }
      };

      const remove : RowActionItem<ListOfLoads> = {
        label: 'Remove',
        icon: 'pi pi-trash',
        command: rowData => {
          if (!rowData?.id) return;
    
          logisticsConfirmDialog({
            message: 'Delete entry?',
            closable: false,
            accept() {
              apiFactory(ListOfLoadsApi)
                .apiListOfLoadsIdDelete({ id: rowData.id! });
            },
          });
        }
      };

      const changelog : RowActionItem<ListOfLoads> = {
        label: 'Changelog',
        icon: 'pi pi-check-square',
        command: (rowData) => changelogModal.show(rowData?.id)
      };

      if (rights.actions?.driverPayed) {
        actions.push(payed);
      }

      if (rights.actions?.remove || !row.factoring) {
        actions.push(remove);
      }

      actions.push(changelog);

      return actions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rights]);

  const columns = useMemo(() => 
    listOfLoadsColumns(listOfLoadsColumnActionItems), 
    [listOfLoadsColumnActionItems]);
  
  const actionItems : ActionMenuItem[] = [
    {
      label: 'Payed',
      icon: 'pi pi-dollar',
      action: (ids: number[], selectedRows: any[]) => {
        const payed = true;
        const changedRows = selectedRows.map(x => { return {...x, payed: payed} });
        apiFactory(ListOfLoadsApi).apiListOfLoadsSelectedPayedPut({requestBody: ids, payed: payed})
          .then(() => dispatch(setEntitiesFieldsWithAction(changedRows)));
      }
    },
    {
      label: 'Not payed',
      icon: 'pi pi-dollar',
      action: (ids: number[], selectedRows: any[]) => {
        const payed = false;
        const changedRows = selectedRows.map(x => { return {...x, payed: payed} });
        apiFactory(ListOfLoadsApi).apiListOfLoadsSelectedPayedPut({requestBody: ids, payed: payed})
          .then(() => dispatch(setEntitiesFieldsWithAction(changedRows)));
      }
    },
    {
      label: 'Factoring',
      icon: 'pi pi-check-square',
      action: (ids: number[], selectedRows: any[]) => {
        const factoring = true;
        const changedRows = selectedRows.map(x => { return {...x, factoring: factoring} });
        apiFactory(ListOfLoadsApi).apiListOfLoadsSelectedFactoringPut({requestBody: ids, factoring: factoring})
          .then(() => dispatch(setEntitiesFieldsWithAction(changedRows)));
      }
    },
    {
      label: 'Not factoring',
      icon: 'pi pi-check-square',
      action: (ids: number[], selectedRows: any[]) => {
        const factoring = false;
        const changedRows = selectedRows.map(x => { return {...x, factoring: factoring} });
        apiFactory(ListOfLoadsApi).apiListOfLoadsSelectedFactoringPut({requestBody: ids, factoring: factoring})
          .then(() => dispatch(setEntitiesFieldsWithAction(changedRows)));
      }
    }
  ]

  const handleRowClick = (e: DataTableRowClickEventParams) => {
    // click on checkbox triggers "onRowClick" event, so add an exception for this case
    if ((e.originalEvent.target as HTMLElement).className.includes('checkbox')) {
      return;
    }

    lolEditModal.show(e.data);
  };

  const onHide = () => {
    lolEditModal.hide();
    lolEditModal.setData({});
  };

  return <>
    <LogisticsDataGrid
      id='listOfLoadsList'
      selectState={selectState}
      getEntitiesPage={getListOfLoadsPage}
      columns={columns}
      leftHeaderElements={[
        <div className='mx-2'>
          <Button className='p-button-success px-5 ml-2 text-base font-bold' onClick={() => lolCreateModal.show({})}>
            <p className='my-0'>Create</p>
            <AddCircleOutlineIcon fontSize='medium' className='ml-1'/>
          </Button>
          <Button className='p-button-primary px-5 ml-2 text-base font-bold' onClick={() => lolExportModal.show(undefined)}>
            <p className='my-0'>Export</p>
            <UploadFileIcon fontSize='medium' className='ml-1'/>
          </Button>
          <VisibilityToggler visible={rights.ownerBill}>
            <Button className='p-button-primary px-5 ml-2 text-base font-bold' onClick={() => lolOwnerBillModal.show(undefined)}>
              <p className='my-0'>Owner Bill</p>
              <UploadFileIcon fontSize='medium' className='ml-1'/>
            </Button>
          </VisibilityToggler>
        </div>
      ]}
      externalFilters={[
        {
          field: 'global',
          ...serverFilterComponent(props => <GlobalFilter {...props} />),
          serverFilterRequest: (filterMetaData, serverFilterModel: ApiListOfLoadsGetRequest) => {
            return { ...serverFilterModel, global: filterMetaData.value} as ApiListOfLoadsGetRequest;
          },
        },
      ]}
      onRowClick={handleRowClick}
      storedDefaultFilterMeta={{
        // @ts-ignore
        global: { value: ``, matchMode: serverFilterFunctionName},
      }}
      alwaysDefaultFilterMeta={{
        // @ts-ignore
        'number': { value: `${dayjs().tz().format('YY-w')}-`, matchMode: serverFilterFunctionName},
      }}
      addSelectionCheckbox
      actions={actionItems}
      hideActions={!rights.actions?.actionsButton}
      others={{
        columnResizeMode: "expand",
        resizableColumns: true,
        selectionMode: 'checkbox',
        multiSortMeta: [{ field: 'number', order: -1 }],
        rowClassName: (rowData: ListOfLoads) => classNames({
          'bg-green-list-row': rowData.factoring,
          'bg-orange-300': rowData.quickPay && !rowData.payed
        }) || ''
      }}
    />
    <ListOfLoadsEdit
      key={lolEditModal.data.id}
      hide={onHide}
      data={lolEditModal.data}
      loading={false}
      visible={lolEditModal.visible}
    />
    <ListOfLoadsCreate
      hide={lolCreateModal.hide}
      data={undefined}
      loading={false}
      visible={lolCreateModal.visible}
    />
    <ListOfLoadsExport
      hide={lolExportModal.hide}
      data={undefined}
      loading={false}
      visible={lolExportModal.visible}
    />
    <ListOfLoadsChangelog
      hide={changelogModal.hide}
      data={changelogModal.data}
      loading={false}
      visible={changelogModal.visible}
    />
    <ListOfLoadsOwnerBill
      hide={lolOwnerBillModal.hide}
      data={undefined}
      loading={false}
      visible={lolOwnerBillModal.visible}
    />
  </>
}

export default ListOfLoadsList;
