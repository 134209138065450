/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleInsuranceOcrResponse
 */
export interface VehicleInsuranceOcrResponse {
    /**
     * 
     * @type {string}
     * @memberof VehicleInsuranceOcrResponse
     */
    insuranceCompany?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof VehicleInsuranceOcrResponse
     */
    insuranceExpires?: Date | null;
}

/**
 * Check if a given object implements the VehicleInsuranceOcrResponse interface.
 */
export function instanceOfVehicleInsuranceOcrResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleInsuranceOcrResponseFromJSON(json: any): VehicleInsuranceOcrResponse {
    return VehicleInsuranceOcrResponseFromJSONTyped(json, false);
}

export function VehicleInsuranceOcrResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleInsuranceOcrResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insuranceCompany': !exists(json, 'insuranceCompany') ? undefined : json['insuranceCompany'],
        'insuranceExpires': !exists(json, 'insuranceExpires') ? undefined : (json['insuranceExpires'] === null ? null : new Date(json['insuranceExpires'])),
    };
}

export function VehicleInsuranceOcrResponseToJSON(value?: VehicleInsuranceOcrResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'insuranceCompany': value.insuranceCompany,
        'insuranceExpires': value.insuranceExpires === undefined ? undefined : (value.insuranceExpires === null ? null : value.insuranceExpires.toISOString().substr(0,10)),
    };
}

