import * as React from 'react';
import { MultiSelect, MultiSelectChangeParams } from 'primereact/multiselect';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import styled from '@emotion/styled';
import { focusedInputStyle, inputStyle } from '../../form/Styles';
import { ESource } from '../../../generated-api';

const options = [
  ESource.Sylectus,
  ESource.MyVirtualFleet,
  ESource.Ivia,
  ESource.FullCircle,
];

const StyledMultiselect = styled(MultiSelect)`
  &.p-multiselect {
    ${inputStyle};
    display: flex;
  }

  &.p-multiselect.p-inputwrapper-focus {
    ${focusedInputStyle}
  }

  &.short {
    width: 30%;
  }
`

export default function SourceFilterInput(props: ColumnFilterElementTemplateOptions) {
  const { value, filterApplyCallback } = props;
  const handleOnChange = (e: MultiSelectChangeParams) => {
    filterApplyCallback(e.value);
  }

  return (
    <StyledMultiselect
      className='short'
      display="chip"
      value={value}
      options={options}
      onChange={handleOnChange}
      placeholder={'Source'}
    />
  );
}